.Home .home-top {
	min-height: 100vh;
	width: 100%;
	overflow: hidden;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.Home .home-top .container {
	background-color: #00000095;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	min-height: inherit;
	max-width: 100%;
}

.Home .home-top .container .logo {
	margin: 0 0 50px 0;
}

.Home .home-top .container .logo img {
	width: 40vw;
	min-width: 300px;
	max-width: 700px;
}

.Home .home-top .container h1 {
	font-size: 35px;
	font-family: "GothamPro-Medium";
	color: #fff;
	margin-bottom: 40px;
	text-align: center;
}

@media (max-width: 500px) {
	.Home .home-top .container h1 {
		font-size: 25px;
		line-height: 1.2;
	}
}

.Home .home-top .container .button {
	padding: 10px 45px;
	margin-top: 50px;
	font-family: "GothamPro-Medium";
	font-size: 22px;
	color: black;
	text-decoration: none;
	border: none;
	background-color: #ffe600;
	cursor: pointer;
	font-weight: bold;
	text-align: center;
	line-height: 1.4;
	display: flex;
	align-items: center;
	overflow: hidden;
}

@media (max-width: 500px) {
	.Home .home-top .container .button {
		font-size: 20px;
		margin-top: 20px;
	}
}

.Home .home-top .container .button:hover span {
	left: 100px;
}

.Home .home-top .container .button span {
	font-size: 45px;
	line-height: 1;
	margin: 0 0 0 15px;
	position: relative;
	top: -6px;
	left: 0;
	transform: scaleX(-1);
	transition: 0.2s;
}

@media (max-width: 500px) {
	.Home .home-top .container .button span {
		top: -3px;
		font-size: 30px;
		margin: 0 0 0 10px;
	}
}

.Home .home-top .container .info {
	margin: 50px 20px 0;
	padding: 10px 30px;
	position: relative;
	font-size: 21px;
	color: #000;
	background-color: #ffe600;
	font-family: "GothamPro-Medium";
	text-align: center;
	line-height: 1.4;
	max-width: 500px;
}

.Home .home-top .container .info i {
	width: 45px;
	height: 45px;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	align-items: center;
	-webkit-justify-content: center;
	-webkit-align-items: center;
	background-color: #ce1515;
	border-radius: 50%;
	font-size: 24px;
	color: #fff;
	position: absolute;
	top: -25px;
	left: -25px;
	box-shadow: 2px 2px 5px #000 3f;
}

.Home .home-top .container .info a {
	line-height: 2;
	padding: 10px 15px;
	background-color: #000 17;
}

.Home .home-top .scroll-down {
	position: absolute;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
}

.Home .home-top .scroll-down i {
	color: #fff 90;
	font-size: 40px;
	transition: 200ms;
	cursor: pointer;
}

.Home .home-top .scroll-down i:hover {
	color: #fff;
}

.Home .video_container {
	z-index: -99;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	background-image: url(../img/bgtop.jpg);
	background-size: cover;
	pointer-events: none;
}

.Home .video_container video {
	z-index: -99;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
}

.Home .video_container video::-webkit-media-controls {
	display: none;
	pointer-events: none;
}

.Home .video_container video::-webkit-media-controls-start-playback-button {
	display: none;
	pointer-events: none;
}