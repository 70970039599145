.Certificate {
    padding-bottom: 100px;
}

.Certificate .price-table-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    position: relative;
    margin: 20px 0;
}

.Certificate .price-table-wrap .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row {
        flex-wrap: wrap;
        border: none;
    }

    .Certificate .price-table-wrap .row:not(.row-head) {
        margin-bottom: 40px;
    }
}

@media (min-width: 851px) {
    .Certificate .price-table-wrap .row:not(.row-head) .cell-price-head {
        display: none;
    }
}

.Certificate .price-table-wrap .row:last-of-type {
    margin-bottom: 0;
}

.Certificate .price-table-wrap .row .cell {
    padding: 20px 30px;
    position: relative;
    font-family: "GothamPro-Bold";
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell {
        padding: 15px;
    }
}

.Certificate .price-table-wrap .row .cell img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}

.Certificate .price-table-wrap .row .cell img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
}

.Certificate .price-table-wrap .row .cell.cell-title {
    width: 60%;
    display: flex;
    flex-direction: column;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-title {
        width: 100%;
    }
}

.Certificate .price-table-wrap .row .cell.cell-title p {
    z-index: 3;
    position: relative;
    margin-top: auto;
    padding-top: 60px;
    font-size: 33px;
    font-family: "GothamPro-Bold";
    color: #fff;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-title p {
        font-size: 28px;
    }
}

.Certificate .price-table-wrap .row .cell.cell-title.gradient::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.95) 100%);
    z-index: 2;
}

.Certificate .price-table-wrap .row .cell.cell-price {
    width: 40%;
    border-left: 6px solid #fff;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-price {
        width: 100%;
    }
}

.Certificate .price-table-wrap .row .cell.cell-benefits {
    width: 100%;
    border-top: 6px solid #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-benefits {
        border: none;
    }
}

.Certificate .price-table-wrap .row .cell.cell-benefits .list-title {
    color: #000;
    font-size: 20px;
    font-family: "GothamPro-Medium";
    margin-bottom: 5px;
}

.Certificate .price-table-wrap .row .cell.cell-benefits .list-values {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-benefits .list-values {
        gap: 6px;
    }
}

.Certificate .price-table-wrap .row .cell.cell-benefits .list-values .benefit-item {
    padding: 10px 20px;
    background-color: #d3e7f9;
    color: #000;
    border-radius: 20px;
    font-size: 16px;
    font-family: "GothamPro-Medium";
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-benefits .list-values .benefit-item {
        padding: 8px 16px;
        font-size: 14px;
    }
}

.Certificate .price-table-wrap .row .cell.cell-price-value {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    color: #000;
}

.Certificate .price-table-wrap .row .cell.cell-price-value span {
    font-size: 25px;
    position: relative;
    top: -2px;
    margin-left: 5px;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-price-value {
        font-size: 20px;
    }

    .Certificate .price-table-wrap .row .cell.cell-price-value span {
        font-size: 20px;
        top: -1px;
    }
}

.Certificate .price-table-wrap .row .cell.green {
    background-color: #69de64;
}

.Certificate .price-table-wrap .row .cell.yellow {
    background-color: #ffe600;
}

.Certificate .price-table-wrap .row .cell.gray {
    background-color: #f5f5f5;
}

@media (max-width: 850px) {

    .Certificate .price-table-wrap .row .cell:nth-child(2),
    .Certificate .price-table-wrap .row .cell:nth-child(4) {
        border-left: none;
    }
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row.row-head .cell {
        display: none;
    }
}