#affiches-wrap { 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.affiches-list {
    width: 100%;
    background-color: #0000000e;
    padding: 0 10px;
    margin-top: 50px;
}

.affiches-list .container {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
}

@media (max-width: 425px) {
    .affiches-list .container {
        padding: 20px 0;
    }
}