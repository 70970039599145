body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: 'GothamPro';
  src:
    url(/static/media/GothamPro.a6ad4f7b.eot) format('eot'),
    url(/static/media/GothamPro.aafeb237.otf) format('opentype'),
    url(/static/media/GothamPro.e13724d3.woff) format('woff'),
    url(/static/media/GothamPro.dfd2ec9d.ttf) format('truetype')
}

@font-face {
  font-family: 'GothamPro-Medium';
  src:
    url(/static/media/GothamPro-Medium.99e6bd94.eot) format('eot'),
    url(/static/media/GothamPro-Medium.dd3d9ca5.otf) format('opentype'),
    url(/static/media/GothamPro-Medium.e26f778a.woff) format('woff'),
    url(/static/media/GothamPro-Medium.be1f6965.ttf) format('truetype')
}

@font-face {
  font-family: 'GothamPro-Bold';
  src:
    url(/static/media/GothamPro-Bold.f5f5a498.eot) format('eot'),
    url(/static/media/GothamPro-Bold.3f9c5a2b.otf) format('opentype'),
    url(/static/media/GothamPro-Bold.fa49a77f.woff) format('woff'),
    url(/static/media/GothamPro-Bold.a30a1d45.ttf) format('truetype')
}
img {
    -webkit-user-select: none;
            user-select: none;
}

body {
    font-family: "GothamPro";
    position: relative;
    min-height: 100vh;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    height: 100%;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

button {
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.content {
    height: 100%;
    min-height: calc(100vh - 190px);
}

.title {
    display: inline-block;
    font-size: 30px;
    font-family: "GothamPro-Bold";
    text-align: center;
    margin: 70px auto 40px;
    background-color: #ffe600;
    padding: 10px 60px;
    color: #000;
}

@media only screen and (max-width: 425px) {
    .title {
        font-size: 30px;
        padding: 10px 30px;
    }
}

.preloader {
    width: 100px;
    height: 100px;
}

.back-btn {
    position: fixed;
    top: 19px;
    left: 70px;
    z-index: 20;
    transition: 200ms;
}

@media (max-width: 480px) {
    .back-btn {
        display: none;
    }
}

.back-btn a {
    font-size: 16px;
    font-family: "GothamPro-Medium";
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 7px 15px;
}

.back-btn a i {
    position: relative;
    margin-right: 8px;
    font-size: 20px;
    left: 0;
    transition: 200ms;
}

.back-btn:hover {
    background-color: #fff;
}

.back-btn:hover i {
    left: -3px;
}

.disclamer {
    display: flex;
    padding: 10px 25px;
    background-color: #f5f5f5;
    color: #000;
    font-family: "GothamPro-Medium";
    font-size: 18px;
    margin: 15px 0;
    position: relative;
    line-height: 1.3;
}

@media (max-width: 850px) {
    .disclamer {
        font-size: 16px;
    }
}

.disclamer:before {
    content: '';
    font-family: "GothamPro-Bold";
    position: absolute;
    top: -8px;
    left: -8px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZTYwMDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPkFzc2V0IDE8L3RpdGxlPjxyZWN0IGNsYXNzPSJhIiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHJ4PSIyNSIvPjxwYXRoIGQ9Ik0yMS4xLDE2LjUxYTQuNTEsNC41MSwwLDAsMSwwLTYuNzdBNS41Nyw1LjU3LDAsMCwxLDI1LDguMzVhNS41Myw1LjUzLDAsMCwxLDMuOSwxLjM5LDQuNTMsNC41MywwLDAsMSwwLDYuNzcsNS41Myw1LjUzLDAsMCwxLTMuOSwxLjRBNS41OCw1LjU4LDAsMCwxLDIxLjEsMTYuNTFaTTIwLDQxLjY1VjIwLjM2SDMwVjQxLjY1WiIvPjwvc3ZnPg==");

}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Header {
    position: fixed;
    z-index: 999;
    width: 100%;
}

.menu {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    padding: 10px;
    position: absolute;
    width: 100%;
    height: 100vh;
    opacity: 0;
}

.menu-blur {
    transition: 500ms;
    display: flex;
    background-color: #000000ec;
    background-repeat: repeat;
    animation: fadeIn 50ms linear forwards;
    height: 100vh;
    width: 100%;

}

.menu .navigation{
    display: none;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.navigation-active {
    opacity: 0;
    display: flex !important;
    animation: fadeIn 400ms linear forwards 200ms;
}

.menu_button {
    position: absolute;
    top: 10px;
    left: 15px;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 9999999;
    font-size: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 5px 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: none;
    transition: 200ms;
}

.menu_button-color {
    color: #000;
}

.menu_button:hover {
    transform: scale(1.1);
}

.menu .navigation ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: -wekit-flex;
    -webkit-justify-content: flex-start;
    -webkit-align-items: center;
}

.menu .navigation ul li {
    opacity: 0;
    width: 100%;
    max-width: 350px;
    list-style: none;
    text-align: center;
    display: flex;
    margin: 15px 15px;
    animation: fadeIn 200ms linear forwards;
    background-color: #ffe600;
    transition: 100ms;
}

.menu .navigation ul li:nth-child(1) {
    transform: rotate(2deg);   
}
.menu .navigation ul li:nth-child(2) {
    animation-delay: 100ms;
    transform: rotate(-1.5deg) translateX(-10px);

}
.menu .navigation ul li:nth-child(3) {
    animation-delay: 150ms;
    transform: rotate(1deg) translateX(10px);
}
.menu .navigation ul li:nth-child(4) {
    animation-delay: 200ms;
    transform: rotate(-2deg) translateX(-10px);    
}
.menu .navigation ul li:nth-child(5) {
    animation-delay: 250ms;
    transform: rotate(1deg);    
}
.menu .navigation ul li:nth-child(6) {
    animation-delay: 300ms;
    transform: rotate(-1deg) translateX(-8px);  
}
.menu .navigation ul li:nth-child(7) {
    animation-delay: 300ms;
    transform: rotate(1deg) translateX(-5px);  
}


.menu .navigation ul li:nth-child(1):hover {
    transform: rotate(2deg) scale(1.03);   
}
.menu .navigation ul li:nth-child(2):hover {
    transform: rotate(-1.5deg) translateX(-10px) scale(1.03);
}
.menu .navigation ul li:nth-child(3):hover {
    transform: rotate(1deg) translateX(10px) scale(1.03);
}
.menu .navigation ul li:nth-child(4):hover {
    transform: rotate(-2deg) translateX(-10px) scale(1.03);    
}
.menu .navigation ul li:nth-child(5):hover {
    transform: rotate(1deg) scale(1.03);    
}
.menu .navigation ul li:nth-child(6):hover {
    transform: rotate(-1deg) translateX(-8px) scale(1.03);    
}
.menu .navigation ul li:nth-child(7):hover {
    transform: rotate(1deg) translateX(-5px) scale(1.03);    
}

.menu .navigation ul li a{
    width: 100%;
    height: 100%;
    font-size: 30px;
    font-family: "GothamPro-Medium";
    padding: 12px 0;
    text-decoration: none;
    color: #000;
    transition: 100ms;
}

.menu .navigation ul li a:hover{
    transform: scale(1.03) !important;
}

.menu .navigation ul li:hover a{
    transform: scale(1.03);
}



@media only screen and (max-width: 500px){

    .Home .container .logo img {
        width: 100% !important;
        max-width: 500px !important;
    }

    .menu .navigation {
        justify-content: flex-start;
    }

    .menu .navigation ul {
        margin-top: 50px;
    }

    .menu .navigation ul li {
        width: 270px;
        margin: 10px 0;
    }

    .menu .navigation ul li a {
        font-size: 22px;
        padding: 17px;
    }


    
}


@media only screen and (max-height: 600px) and (max-width: 820px) {

    .Home .container .logo img {
        width: 100% !important;
        max-width: 500px !important;
    }

    .menu {
        overflow-y: scroll;
    }

    .menu .navigation {
        justify-content: flex-start;
    }

    .menu .navigation ul {
        min-height: calc(100vh + 100px);
        margin: 70px 0 0 0;
    }

    .menu .navigation ul li {
        min-height: 50px;
        width: 270px;
        margin: 10px 0;
    }
    
    .menu .navigation ul li a {
        font-size: 22px;
        padding: 15px 0;
    }

}


@media only screen and (max-height: 330px) and (max-width: 820px) {

    .menu .navigation ul {
        min-height: 600px;
        margin: 70px 0 100px 0;
    }

}
.Home .home-top {
	min-height: 100vh;
	width: 100%;
	overflow: hidden;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.Home .home-top .container {
	background-color: #00000095;
	display: flex;
	flex-direction: column;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	min-height: inherit;
	max-width: 100%;
}

.Home .home-top .container .logo {
	margin: 0 0 50px 0;
}

.Home .home-top .container .logo img {
	width: 40vw;
	min-width: 300px;
	max-width: 700px;
}

.Home .home-top .container h1 {
	font-size: 35px;
	font-family: "GothamPro-Medium";
	color: #fff;
	margin-bottom: 40px;
	text-align: center;
}

@media (max-width: 500px) {
	.Home .home-top .container h1 {
		font-size: 25px;
		line-height: 1.2;
	}
}

.Home .home-top .container .button {
	padding: 10px 45px;
	margin-top: 50px;
	font-family: "GothamPro-Medium";
	font-size: 22px;
	color: black;
	text-decoration: none;
	border: none;
	background-color: #ffe600;
	cursor: pointer;
	font-weight: bold;
	text-align: center;
	line-height: 1.4;
	display: flex;
	align-items: center;
	overflow: hidden;
}

@media (max-width: 500px) {
	.Home .home-top .container .button {
		font-size: 20px;
		margin-top: 20px;
	}
}

.Home .home-top .container .button:hover span {
	left: 100px;
}

.Home .home-top .container .button span {
	font-size: 45px;
	line-height: 1;
	margin: 0 0 0 15px;
	position: relative;
	top: -6px;
	left: 0;
	transform: scaleX(-1);
	transition: 0.2s;
}

@media (max-width: 500px) {
	.Home .home-top .container .button span {
		top: -3px;
		font-size: 30px;
		margin: 0 0 0 10px;
	}
}

.Home .home-top .container .info {
	margin: 50px 20px 0;
	padding: 10px 30px;
	position: relative;
	font-size: 21px;
	color: #000;
	background-color: #ffe600;
	font-family: "GothamPro-Medium";
	text-align: center;
	line-height: 1.4;
	max-width: 500px;
}

.Home .home-top .container .info i {
	width: 45px;
	height: 45px;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	align-items: center;
	-webkit-justify-content: center;
	-webkit-align-items: center;
	background-color: #ce1515;
	border-radius: 50%;
	font-size: 24px;
	color: #fff;
	position: absolute;
	top: -25px;
	left: -25px;
	box-shadow: 2px 2px 5px #000 3f;
}

.Home .home-top .container .info a {
	line-height: 2;
	padding: 10px 15px;
	background-color: #000 17;
}

.Home .home-top .scroll-down {
	position: absolute;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
}

.Home .home-top .scroll-down i {
	color: #fff 90;
	font-size: 40px;
	transition: 200ms;
	cursor: pointer;
}

.Home .home-top .scroll-down i:hover {
	color: #fff;
}

.Home .video_container {
	z-index: -99;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	background-image: url(/static/media/bgtop.7a8e7f21.jpg);
	background-size: cover;
	pointer-events: none;
}

.Home .video_container video {
	z-index: -99;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
}

.Home .video_container video::-webkit-media-controls {
	display: none;
	pointer-events: none;
}

.Home .video_container video::-webkit-media-controls-start-playback-button {
	display: none;
	pointer-events: none;
}
.Affiche {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 30px;
    box-shadow: 0 5px 17px rgba(0, 0, 0, 0.089);
}

.affiche-content {
    display: flex;
    min-height: 220px;
}

.affiche-date {
    min-width: 170px;
    width: 170px;
    padding: 20px 15px;
    background-color: #ffe600;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.affiche-date-day {
    font-family: "GothamPro-Bold";
    font-size: 40px;
    letter-spacing: 2px;
    margin: 0 0 5px;
    color: #11111190;
}

.affiche-date-month {
    font-size: 20px;
    margin: 0 0 5px;
    color: #11111190;
    font-family: "GothamPro-Medium";
}

.affiche-days-to {
    margin-top: auto;
    color: #11111190;
    font-size: 15px;
}

.affiche-soon {
    padding: 7px 12px;
    background-color: #f04a4a;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
}

.affiche-main {
    padding: 15px;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.affiche-thumb {
    min-width: 150px;
    width: 150px;
}

.affiche-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.affiche-title {
    font-family: "GothamPro-Medium";
    font-size: 22px;
    margin-bottom: 11px;
}

.affiche-description {
    font-family: "GothamPro-Medium";
    font-size: 15px;
    color: #888888;
    max-width: 600px;
    line-height: 1.3;
}

.extra-info {
    margin-top: auto;
    padding-top: 20px;
}

.extra-info-row {
    display: flex;
    font-family: "GothamPro-Medium";
    font-size: 15px;
    color: #000000;
    max-width: 600px;
    line-height: 1.3;
    margin-bottom: 5px;
}

.extra-info-row-fee {
    margin-top: 10px;
    color: #888888;
}

.row-label {
    margin-right: 20px;
    width: 100px
}

.row-value a {
    color: #2e87ec;
}

.affiche-img {
    max-width: 300px;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
}

.affiche-img img {
    height: 100%;
}

.affiche-links {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.affiche-link {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.link-label {
    font-family: "GothamPro-Medium";
    margin-right: 7px;
    color: #888;
    font-size: 15px;
}

.link-value {
    font-family: "GothamPro-Medium";
    color: #2e87ec;
    font-size: 15px;
    transition: 100ms;
}

.link-value:hover {
    color: #5ca8ff;
}





@media (max-width: 700px){
    .Affiche {
        height: auto;
        min-height: 250px;
    }

    .affiche-content {
        flex-direction: column;
        position: relative;
    }

    .affiche-date-day {
        font-size: 45px;
    }

    .affiche-date {
        width: 100%;
    }

    .affiche-date.has-thumb {
        height: 200px;
        padding-right: 150px;
    }

    .affiche-thumb {
        position: absolute;
        height: 200px;
        width: 135px;
        min-width: 135px;
        right: 0;
    }
   
}
#affiches-wrap { 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.affiches-list {
    width: 100%;
    background-color: #0000000e;
    padding: 0 10px;
    margin-top: 50px;
}

.affiches-list .container {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
}

@media (max-width: 425px) {
    .affiches-list .container {
        padding: 20px 0;
    }
}
.error-page {
    z-index: auto;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #00000085;
}

.error-page h2 {
    font-family: "GothamPro-Bold";
    font-size: 350px;
    font-weight: bolder;
    color: #00000046;
    margin-bottom: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.error-page h3 {
    text-align: center;
    color: #fff;
    font-size: 40px;
    line-height: 1.2;
}

.error-page a {
    margin-top: 40px;
    padding: 15px 50px;
    background-color: #ffe600;
    text-decoration: none;
    font-size: 25px;
    color: #000;
    font-family: "GothamPro-Medium";
}
.error-page a span {
    display: block;
    transition: 200ms;
}   


.error-page a:hover span {
    transform: scale(1.05);
}



video::-webkit-media-controls {
    display: none;
    pointer-events: none;
}
video::-webkit-media-controls-start-playback-button {
    display: none;
    pointer-events: none;
}


.video_container {
    z-index: -2;
    width: 100%;
    height: 100%; 
    overflow: hidden;
    position: absolute;
    background-image: url(/static/media/bgtop.7a8e7f21.jpg);
    background-size: cover;
    pointer-events: none;
}

.video_container video {
    z-index: -2;
    min-width: 100%; 
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none;
}



@media only screen and (max-width:425px){
    .error-page h2 {
        display: none;
    }
}
@keyframes pulse {
    0% {
        box-shadow: 0px 0px 1px #ffe600;
    }

    50% {
        box-shadow: 0px 0px 7px 2px #fff06e;
    }

    100% {
        box-shadow: 0px 0px 1px #ffe600;
    }

}


.About .title {
    transform: rotate(1deg);
}

.track__img {
    margin: 50px 0 0;
    width: 100%;
    min-width: 750px;
    min-height: 300px;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    position: relative;
}

.track__img_inner {
    position: relative;
}

.track__img_inner-loading {
    display: none;
}

.track__img_inner img {
    width: 100%;
    margin: 0 auto;
    -webkit-user-select: none;
            user-select: none;
    -webkit-user-drag: none;
    z-index: -1;
}

.track_pin_wrap {
    position: absolute;
}

.track_pin {
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #ffe600;
    background-color: #ffe60086;
    animation: pulse 1.7s linear infinite;
    z-index: 99;
    cursor: pointer;
}

.track_pin_descr {
    position: absolute;
    bottom: 30px;
    left: 30px;
    padding: 10px;
    min-width: 220px;
    max-width: 500px;
    background-color: #ffe600;
    border-radius: 5px;
    box-shadow: 0px 5px 10px #00000040;
    font-family: "GothamPro-Medium";
    font-size: 14px;
    line-height: 1.2;
    -webkit-clip-path: circle(0% at 5% 90%);
            clip-path: circle(0% at 5% 90%);
    transition: .3s ease-in-out;
}

.track_pin:hover,
.track_pin:focus {
    background-color: #ffe600;
    animation: none;
}

.track_pin:hover+.track_pin_descr,
.track_pin:focus+.track_pin_descr {
    -webkit-clip-path: circle(100%);
            clip-path: circle(100%)
}

.track_pin_wrap:nth-child(1) {
    top: 33%;
    left: 49%;
}

.track_pin_wrap:nth-child(2) {
    top: 68%;
    left: 50%;
}

.track_pin_wrap:nth-child(3) {
    top: 76%;
    left: 14%;
}

.track_pin_wrap:nth-child(4) {
    top: 60%;
    left: 17%;
}

.track_pin_wrap:nth-child(5) {
    top: 27%;
    left: 21%;
}

.track_pin_wrap:nth-child(6) {
    top: 31%;
    left: 9%;
}

.track_pin_wrap:nth-child(7) {
    top: 34%;
    left: 74%;
}

.track_pin_wrap:nth-child(8) {
    top: 28%;
    left: 34%;
}

.track__title {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-justify-content: center;
    width: 100%;
}

.track__title p {
    max-width: 900px;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
}

.track__title ul {
    padding-left: 20px;
    margin-bottom: 10px;
}


.scroll_img {
    margin: 0px 0px 30px 0px;
    display: none;
    width: 100%;
    height: 60px;
    background-image: url(/static/media/scroll.99557b88.jpg);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.track__descr {
    max-width: 900px;
}

.track__descr__p {
    width: 100%;
    margin-bottom: 50px;
}

.track__descr__p p {
    font-size: 20px;
    line-height: 28px;
}

.track__descr__p__title p span {
    margin-right: 10px;
    font-size: 23px;
    background-color: #ffe600;
    padding: 8px 30px;
    color: 000;
}

.track__descr__p__title {
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 4px solid #ffe600;
    display: inline-block;
}

.track__img .preloader {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.track__img .preloader-disable {
    display: none;
}


@media only screen and (max-width: 720px) {

    .track__img {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll;
        overflow-y: hidden;
        justify-content: flex-start;
        min-width: auto;
        margin: 50px 0px 10px 0px;
    }

    .track__img img {
        width: 1200px;
        height: 100%;
    }

    .scroll_img {
        display: block;
    }


}

@media only screen and (max-width: 425px) {


    .track__descr__p__title {
        display: flex;
        flex-direction: column;
        align-self: center;
    }

    .track__descr__p__title p {
        text-align: center;
    }


    .track__descr__p__title p span {
        display: block;
        margin-right: auto;
    }


}
.events-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.EventThumb {
    width: 50%;
    padding: 15px;
}

.event_thumb-box {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
}


.event_thumb-box:hover .event_thumb-box-img{
    transform: translate(-50%, -50%) scale(1.04);
    opacity: 0.9;
}

.event_thumb-box-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 400ms ease-out;
    background-color: #000;
}

.event_thumb-box-img-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 400ms ease-out;
    opacity: 0
}

.event_thumb-box .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.event_thumb-title {
    position: relative;
    z-index: 10;
    padding: 10px 20px 8px;
    background-color: #ffe600;
    font-family: "GothamPro-Bold";
    font-size: 22px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 2px 2px 7px #00000090;
    margin: 10px;
    color: #000;
}

.event_thumb-date {
    position: relative;
    z-index: 10;
    font-family: "GothamPro-Medium";
    font-size: 17px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #fff;
    margin: 10px;
    text-shadow: 1px 1px 4px #00000096;
}

.event_thumb-link {
    z-index: 10;
    padding: 8px;
    border: 2px solid #fff;
    font-family: "GothamPro-Medium";
    font-size: 15px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    width: 100px;
    box-shadow: 2px 2px 7px #00000090;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #00000040;
}

.event_thumb-link:hover {
    background-color: #00c2f3d0;
}



@media only screen and (max-width: 850px){
    .EventThumb {
        width: 100%;
        padding: 15px;
    }
}

@media only screen and (max-width: 425px) {
    .event_thumb-box-img {
        width: auto;
        height: 100%;
    }
    
}
.EventImages {
    height: 100%; 
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.ril__caption, .ril__toolbar {
    background-color: transparent !important;
}
.event-cell-wrap{

    width: 20%;
    padding: 5px;
}
.event-cell {
    width: 100%;
    height: 150px;
}

.event-img-cell {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: transform 300ms;
    cursor: pointer;
    background-image: url(/static/media/preloader.9eb38563.gif);
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;
}

.event-img-cell:hover img {
    transform: translate(-50%, -50%) scale(1.07);
}

.event-img-cell img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: 300ms;
    background-color: transparent;
}

.event-img-cell:hover {
    z-index: 1;
    transform: scale(1.03);
    box-shadow: 0px 4px 15px #00000090;
    outline: 3px solid #ffe600;
}

.event-img-cell iframe {
    height: 100%;
    width: 100%;
    border: none;
}

@media only screen and (max-width: 1024px){
    .event-cell-wrap{
        width: 25%;
    }    
}
@media only screen and (max-width: 880px){
    .event-cell-wrap{
        width: 33.33%;
    }    
}
@media only screen and (max-width: 670px){
    .event-cell-wrap{
        width: 50%;
    }    
}

.event{
    width: 100%;
    height: 100%;
    margin: 70px 0;
}

.event__title {
    font-family: "GothamPro-Medium"; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-left: auto;
}

.event__title__name {
    color: #111111;
    font-family: "GothamPro-Bold";
    font-size: 30px;
    background-color: #ffe600;
    padding: 8px 20px;
}

.event__title__date {
    margin-bottom: 6px;
    font-size: 15px;
    color: #636363;
}

.event__title-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 50px;
    padding: 0 5px;
}


.event-detail-back {
    text-decoration: none;
}

.event-detail-back button{
    font-family: "GothamPro-Medium";
    display: inline-block;
    align-items: center;
    color: #000;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transition: 100ms;
}

.event-detail-back i {
    font-size: 18px;
    margin-right: 5px;
}

.event-detail-back span {
    font-size: 16px;
}



@media only screen and (max-width:425px) {


    .event__title-row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        padding: 0 5px;
    }

    .event__title {
        flex-direction: column;
        align-items: center;
    }

    .event__title__name {
        font-size: 25px;
    }

    .event-detail-back button{
        margin-bottom: 30px;
    }

}
.Booking {
    min-height: 100vh;
}

.days-list {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}
.Day {
    width: 100%;
    max-width: 500px;
    min-height: 100px;
    margin-bottom: 100px;
}

.day-helper {
    color: #bdbdbd;
    font-size: 15px;
    margin-bottom: 5px;
}

.day-head {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 30px;
}

.day-main-title {
    font-size: 35px;
    color: #000;
    font-family: "GothamPro-Bold"
}

.day-main-date {
    font-family: "GothamPro-Bold";
    margin: 0 0 3px 15px;
    font-size: 23px;
    color: #bdbdbd;
}

.day-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.day-event {
    height: 110px;
    width: 250px;
    background-color: #ffe600;
    box-shadow: 0 4px 6px #00000030;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.day-event-time {
    font-size: 25px;
    font-family: "GothamPro-Bold";
    color: #000;
}

.cars-avaliable {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.cars-avaliable p:last-child{
    font-family: "GothamPro-Bold";
    font-size: 20px;
    margin-top: 5px;
}

.cars-avaliable p:last-child span{
    font-family: "GothamPro";
    font-size: 16px;
}

.day-free {
    text-align: center;
    font-size: 20px;
    color: #bdbdbd;
}




@media only screen and (max-width:425px){
    .day-head {
        flex-direction: column;
        align-items: center;
    }

    .day-main-title {
        margin-bottom: 10px;
    }
}
.Contacts {
    margin: 0 100px 50px;
}

.contacts-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
    margin-top: 100px;
    font-family: "GothamPro-Bold"
}

.contacts-main a {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: none;
}

.contacts-worktime-day {
    display: flex;
    flex-direction: column;
}

.contacts-worktime-day span:nth-child(2) {
    font-size: 16px;
    margin-top: 5px;
}

.contacts-worktime-time {
    font-size: 20px;
    padding: 10px 25px;
    background-color: #ffe600;
    display: -webkit-inline-box;
    margin-top: 13px;
    transform: rotate(-1deg);

}

.contacts-address, .contacts-digit, .contacts-worktime {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
}

.contacts-address-title, .contacts-digit-title, .contacts-worktime-title {
    color: #bdbdbd;
    font-size: 20px;
    margin-bottom: 10px;
}

.contacts-address-value, .contacts-digit-value, .contacts-worktime-day {
    line-height: 1.2;
    color: #000;
    font-size: 25px;
    margin-bottom: 5px;
}

.contacts-address-value span {
    font-size: 16px;
}

.contacts-digit-value {
    font-size: 22px;
} 

.contacts-address-link {
    color: #000;
    font-size: 20px;
    padding: 10px 25px;
    background-color: #ffe600;
    margin-top: 10px;
    transform: rotate(2deg);
    transition: 100ms;
}

.contacts-address-link:hover {
    transform: scale(1.05) rotate(1deg);
}


@media only screen and (max-width: 425px) {
    .Contacts {
        margin: 0 0 50px;
    }
}
.ServiceCard-main {
    margin: 0 0 70px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (max-width: 500px) {
    .ServiceCard-main {
        margin: 0 0 50px 0;
    }
}

.ServiceCard-main .ServiceCard-wrap {
    display: flex;
    position: relative;
    padding: 80px 100px;
    width: 100%;
    background-size: cover;
    background-position: center;
}

@media (max-width: 1024px) {
    .ServiceCard-main .ServiceCard-wrap {
        padding: 30px 60px 80px 60px;
    }
}

@media (max-width: 500px) {
    .ServiceCard-main .ServiceCard-wrap {
        padding: 30px 20px 80px 20px;
    }
}

.ServiceCard-main .ServiceCard-wrap.service-item-1 {
    background-image: url(/static/media/service-img-1.bd40b2eb.jpg);
}

.ServiceCard-main .ServiceCard-wrap.service-item-2 {
    background-image: url(/static/media/service-img-2.610ee6c3.jpg);
}

.ServiceCard-main .ServiceCard-wrap.service-item-3 {
    background-image: url(/static/media/service-img-3.0b8de2df.jpg);
}

.ServiceCard-main .ServiceCard-wrap.service-item-4 {
    background-image: url(/static/media/service-img-4.8ba8cfe5.jpg);
}

.ServiceCard-main .ServiceCard-wrap.service-item-5 {
    background-image: url(/static/media/service-img-5.201b7b50.jpg);
}

.ServiceCard-main .ServiceCard-wrap:after,
.ServiceCard-main .ServiceCard-wrap:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.1s opacity;
}

.ServiceCard-main .ServiceCard-wrap:after {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.95) 100%);
    opacity: 1;
}

.ServiceCard-main .ServiceCard-wrap:hover:after {
    opacity: 0.7;
}

.ServiceCard-main .ServiceCard-wrap:hover .ServiceCard::after {
    transform: scale(1.1);
    right: 0;
}

.ServiceCard-main .ServiceCard-wrap:hover .ServiceCard-title {
    transform: scale(1.05) rotate(-0.5deg);
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard:after {
    content: '';
    background-image: url('data:image/svg+xml; base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMzUuMjggMjMxLjAyIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48dGl0bGU+QXNzZXQgMTwvdGl0bGU+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNTEuNjMsMCwxMTguOSwzNS4xM2w1Miw1NS44NkgwVjEzOS45SDE3MS4wNmwtNTIuMTYsNTZMMTUxLjYzLDIzMWw4My42NS04OS44M1Y4OS43OVoiLz48L3N2Zz4=');
    background-size: 100%;
    width: 50px;
    height: 50px;
    background-position: center;
    position: absolute;
    top: 0;
    right: 10px;
    transition: 0.15s;
}

@media (max-width: 1024px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard:after {
        top: unset;
        bottom: -40px;
        width: 80px;
        height: 80px;
        opacity: 0.3;
    }
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-title {
    font-size: 25px;
    font-family: "GothamPro-Bold";
    text-align: left;
    line-height: 1.2;
    background-color: #ffe600;
    padding: 10px 60px;
    color: #000;
    transition: 0.15s;
    z-index: 1;
}

@media (max-width: 500px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-title {
        padding: 10px;
        font-size: 20px;
    }
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-description {
    font-family: "GothamPro-Medium";
    line-height: 1.4;
    margin-top: 50px;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}

@media (min-width: 1025px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-description {
        width: 70%;
    }
}

@media (max-width: 500px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-description {
        font-size: 16px;
        margin-top: 25px;
    }
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-icon {
    position: absolute;
    left: -80px;
    top: -150px;
    width: 200px;
    height: 200px;
    pointer-events: none;
    transition: 0.1s;
}

@media (max-width: 1024px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-icon {
        top: -100px;
    }
}

@media (max-width: 500px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-icon {
        top: -90px;
        left: -40px;
        width: 150px;
        height: 150px;
    }
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.services-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 30px auto 0;

}
.rent-section {
    margin-bottom: 110px;
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
}

.rent-about {
    margin-top: 30px;
}


.rent-subtitle {
    font-family: "GothamPro-Medium";
    font-size: 35px;
    color: #bdbdbd;
    margin-bottom: 25px;
}

.rent-about-descr {
    font-family: "GothamPro-Medium";
    font-size: 20px;
    line-height: 1.45;
}

.rent-for-row {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.rent-for-row-mark {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #ffe600;
}

.rent-for-row-item {
    font-family: "GothamPro-Medium";
    font-size: 20px;
    margin-left: 10px;
}

.rent-min-item {
    font-family: "GothamPro-Medium";
    font-size: 20px;
    margin: 4px 0;
    line-height: 1.3
}

.Rent sup {
    font-size: 12px;
    vertical-align: top; 
    position: relative; 
}

.rent-min-item span {
    color: #bdbdbd;
    margin-right: 10px; 
}





.rent-example-item:last-child {
    margin: 0 !important;
}
.rent-example-item {
    display: flex;
    margin-bottom: 60px;
}

.rent-example-item__info {
    display: flex;
    flex-direction: column;
    width: 350px;
}

.rent-example-item__info__name {
    color: #000;
    font-size: 20px;
    background-color: #ffe600;
    padding: 10px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "GothamPro-Medium";
    margin: 5px 0 20px;
}

.rent-example-item-li {
    font-size: 18px;
    color: #000;
    font-family: "GothamPro-Medium";
    margin-bottom: 7px;
}
.rent-example-item-li span{
    color: #9e9e9e;
    margin-right: 15px;
}

.rent-example-item-descr {
    font-family: "GothamPro-Medium";
    margin-top: 20px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 1.3;
    color: #9e9e9e;
}



.rent-estimation-row {
    display: flex;
    align-items: center;
    margin: 4px 0;
}

.rent-estimation-row-title {
    font-family: "GothamPro-Medium";
    font-size: 20px;
    width: 230px;
}

.select-style {
    border: 2px solid #bdbdbd;
    width: 75px;
    overflow: hidden;
    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAMAAABLuo1aAAAAD1BMVEUAAABMaXEAAAAAAAAAAABIdVvnAAAABHRSTlOgANAQYneq5AAAAAlwSFlzAAAA9wAAAPcBuQ9IfwAAACRJREFUCJktxsERACAQwsDEs/+aHfB4kOXcHQyfiNapdWMO1QcOSwBpTDcNZAAAAABJRU5ErkJggg==) no-repeat 90% 50%;
}

.select-style select {
    font-family: "GothamPro-Medium";
    font-size: 20px;
    padding: 8px 15px 7px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    cursor: pointer;
}

.select-style select:focus {
    outline: none;
}

.rent-estimation-result {
    margin-top: 20px;
    font-family: "GothamPro-Medium";
    font-size: 25px;
    padding: 10px 20px;
    background-color: #ffe600;
}

.rent-estimation-comment {
    font-family: "GothamPro-Medium";
    font-size: 18px;
    color: #bdbdbd;
    max-width: 500px;
    margin-top: 20px;
    line-height: 1.2
}





@media only screen and (max-width: 600px) {

    .rent-example-item__info {
        width: 100%;
    }

    .rent-example-item {
        flex-direction: column;
    }
}

.RentImages {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
}

.RentImages .event-cell {
    width: calc(50% - 10px);
    height: 120px;
    margin: 5px;
}


@media only screen and (max-width: 800px){
    .RentImages .event-cell {
        width: 100%;
    }
     
}
.TopSectionServices {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    position: relative;
    margin-bottom: 30px;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.TopSectionServices .section-title {
    z-index: 11;
    display: inline-block;
    font-size: 30px;
    font-family: "GothamPro-Bold";
    text-align: center;
    background-color: #ffe600;
    padding: 10px 60px;
    color: #000;
}

@media (max-width: 480px){
    .TopSectionServices {
        background-size: auto 140%;
    }

    .TopSectionServices .section-title {
        font-size: 22px;
    }
    
}
.Price {
    margin-bottom: 100px;
}

.Price .price-table-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    position: relative;
}

.Price .price-table-wrap .row {
    display: flex;
    border-bottom: 6px solid #fff;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row {
        flex-wrap: wrap;
        border: none;
    }

    .Price .price-table-wrap .row:not(.row-head) {
        margin-bottom: 40px;
    }
}

@media (min-width: 851px) {
    .Price .price-table-wrap .row:not(.row-head) .cell-price-head {
        display: none;
    }
}

.Price .price-table-wrap .row .cell {
    padding: 20px 30px;
    position: relative;
    font-family: "GothamPro-Bold";
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell {
        padding: 15px 20px;
    }
}

.Price .price-table-wrap .row .cell img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}

.Price .price-table-wrap .row .cell img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
}

.Price .price-table-wrap .row .cell.cell-title {
    width: 50%;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-title {
        width: 100%;
    }
}

.Price .price-table-wrap .row .cell.cell-title p {
    z-index: 3;
    position: relative;
    margin-top: 60px;
    font-size: 33px;
    font-family: "GothamPro-Bold";
    color: #fff;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-title p {
        font-size: 28px;
    }
}

.Price .price-table-wrap .row .cell.cell-title .duration {
    position: relative;
    font-size: 16px;
    font-family: "GothamPro-Medium";
    background-color: #ffe600;
    padding: 5px 15px;
    color: #000;
    z-index: 3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 15px;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-title .duration {
        font-size: 14px;
        margin-top: 10px;
    }
}

.Price .price-table-wrap .row .cell.cell-title.gradient::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.95) 100%);
    z-index: 2;
}

.Price .price-table-wrap .row .cell.cell-price {
    width: 25%;
    border-left: 6px solid #fff;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-price {
        width: 50%;
    }
}

.Price .price-table-wrap .row .cell.cell-price-head {
    font-size: 20px;
    color: #000;
    text-align: center;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-price-head {
        border-bottom: 6px solid #fff;
        font-size: 20px;
    }
}

.Price .price-table-wrap .row .cell.cell-price-value {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    color: #000;
}

.Price .price-table-wrap .row .cell.cell-price-value span {
    font-size: 25px;
    position: relative;
    top: -2px;
    margin-left: 5px;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-price-value {
        width: 50%;
        font-size: 20px;
    }

    .Price .price-table-wrap .row .cell.cell-price-value span {
        font-size: 20px;
        top: -1px;
    }
}

.Price .price-table-wrap .row .cell.green {
    background-color: #69de64;
}

.Price .price-table-wrap .row .cell.yellow {
    background-color: #ffe600;
}

.Price .price-table-wrap .row .cell.gray {
    background-color: #f0f0f0;
}

@media (max-width: 850px) {

    .Price .price-table-wrap .row .cell:nth-child(2),
    .Price .price-table-wrap .row .cell:nth-child(4) {
        border-left: none;
    }
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row.row-head .cell {
        display: none;
    }
}
.Train {
    padding-bottom: 100px;
}

.Train h2 {
    font-size: 25px;
    font-family: "GothamPro-Medium";
    margin: 20px;
    line-height: 1.3;
}

@media (max-width: 850px) {
    .Train h2 {
        font-size: 20px;
        margin: 10px;
    }
}

.Train .price-table-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    position: relative;
    margin: 20px 0;
}

.Train .price-table-wrap .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.Train .price-table-wrap .row.no-gap {
    margin: 0;
    margin-bottom: 6px;
}

@media (max-width: 850px) {
    .Train .price-table-wrap .row {
        flex-wrap: wrap;
        border: none;
    }

    .Train .price-table-wrap .row:not(.row-head) {
        margin-bottom: 40px;
    }
}

@media (min-width: 851px) {
    .Train .price-table-wrap .row:not(.row-head) .cell-price-head {
        display: none;
    }
}

.Train .price-table-wrap .row:last-of-type {
    margin-bottom: 0;
}

.Train .price-table-wrap .row .cell {
    padding: 20px 30px;
    position: relative;
    font-family: "GothamPro-Bold";
}

@media (max-width: 850px) {
    .Train .price-table-wrap .row .cell {
        padding: 15px;
    }
}

.Train .price-table-wrap .row .cell img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}

.Train .price-table-wrap .row .cell img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
}

.Train .price-table-wrap .row .cell.cell-title {
    width: 60%;
    display: flex;
    flex-direction: column;
}

@media (max-width: 850px) {
    .Train .price-table-wrap .row .cell.cell-title {
        width: 100%;
    }
}

.Train .price-table-wrap .row .cell.cell-title p {
    z-index: 3;
    position: relative;
    margin-top: auto;
    padding-top: 60px;
    font-size: 33px;
    font-family: "GothamPro-Bold";
    color: #fff;
}

@media (max-width: 850px) {
    .Train .price-table-wrap .row .cell.cell-title p {
        font-size: 28px;
    }
}

.Train .price-table-wrap .row .cell.cell-title.gradient::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.95) 100%);
    z-index: 2;
}

.Train .price-table-wrap .row .cell.cell-price {
    width: 40%;
    border-left: 6px solid #fff;
}

@media (max-width: 850px) {
    .Train .price-table-wrap .row .cell.cell-price {
        width: 100%;
    }
}

.Train .price-table-wrap .row .cell.cell-benefits {
    width: 100%;
    border-top: 6px solid #fff;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

@media (max-width: 850px) {
    .Train .price-table-wrap .row .cell.cell-benefits {
        border: none;
    }
}

.Train .price-table-wrap .row .cell.cell-benefits .list-title {
    color: #000;
    font-size: 20px;
    font-family: "GothamPro-Medium";
    margin-bottom: 5px;
}

.Train .price-table-wrap .row .cell.cell-benefits .list-values {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
}

@media (max-width: 850px) {
    .Train .price-table-wrap .row .cell.cell-benefits .list-values {
        grid-gap: 6px;
        gap: 6px;
    }
}

.Train .price-table-wrap .row .cell.cell-benefits .list-values .benefit-item {
    padding: 10px 20px;
    background-color: #d3e7f9;
    color: #000;
    border-radius: 20px;
    font-size: 16px;
    font-family: "GothamPro-Medium";
}

@media (max-width: 850px) {
    .Train .price-table-wrap .row .cell.cell-benefits .list-values .benefit-item {
        padding: 8px 16px;
        font-size: 14px;
    }
}

.Train .price-table-wrap .row .cell.cell-price-value {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    color: #000;
}

.Train .price-table-wrap .row .cell.cell-price-value span {
    font-size: 25px;
    position: relative;
    top: -2px;
    margin-left: 5px;
}

@media (max-width: 850px) {
    .Train .price-table-wrap .row .cell.cell-price-value {
        font-size: 20px;
    }

    .Train .price-table-wrap .row .cell.cell-price-value span {
        font-size: 20px;
        top: -1px;
    }
}

.Train .price-table-wrap .row .cell.green {
    background-color: #69de64;
}

.Train .price-table-wrap .row .cell.yellow {
    background-color: #ffe600;
}

.Train .price-table-wrap .row .cell.gray {
    background-color: #f5f5f5;
}

@media (max-width: 850px) {

    .Train .price-table-wrap .row .cell:nth-child(2),
    .Train .price-table-wrap .row .cell:nth-child(4) {
        border-left: none;
    }
}

@media (max-width: 850px) {
    .Train .price-table-wrap .row.row-head .cell {
        display: none;
    }
}
.Certificate {
    padding-bottom: 100px;
}

.Certificate .price-table-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    position: relative;
    margin: 20px 0;
}

.Certificate .price-table-wrap .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row {
        flex-wrap: wrap;
        border: none;
    }

    .Certificate .price-table-wrap .row:not(.row-head) {
        margin-bottom: 40px;
    }
}

@media (min-width: 851px) {
    .Certificate .price-table-wrap .row:not(.row-head) .cell-price-head {
        display: none;
    }
}

.Certificate .price-table-wrap .row:last-of-type {
    margin-bottom: 0;
}

.Certificate .price-table-wrap .row .cell {
    padding: 20px 30px;
    position: relative;
    font-family: "GothamPro-Bold";
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell {
        padding: 15px;
    }
}

.Certificate .price-table-wrap .row .cell img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}

.Certificate .price-table-wrap .row .cell img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
}

.Certificate .price-table-wrap .row .cell.cell-title {
    width: 60%;
    display: flex;
    flex-direction: column;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-title {
        width: 100%;
    }
}

.Certificate .price-table-wrap .row .cell.cell-title p {
    z-index: 3;
    position: relative;
    margin-top: auto;
    padding-top: 60px;
    font-size: 33px;
    font-family: "GothamPro-Bold";
    color: #fff;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-title p {
        font-size: 28px;
    }
}

.Certificate .price-table-wrap .row .cell.cell-title.gradient::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.95) 100%);
    z-index: 2;
}

.Certificate .price-table-wrap .row .cell.cell-price {
    width: 40%;
    border-left: 6px solid #fff;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-price {
        width: 100%;
    }
}

.Certificate .price-table-wrap .row .cell.cell-benefits {
    width: 100%;
    border-top: 6px solid #fff;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-benefits {
        border: none;
    }
}

.Certificate .price-table-wrap .row .cell.cell-benefits .list-title {
    color: #000;
    font-size: 20px;
    font-family: "GothamPro-Medium";
    margin-bottom: 5px;
}

.Certificate .price-table-wrap .row .cell.cell-benefits .list-values {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-benefits .list-values {
        grid-gap: 6px;
        gap: 6px;
    }
}

.Certificate .price-table-wrap .row .cell.cell-benefits .list-values .benefit-item {
    padding: 10px 20px;
    background-color: #d3e7f9;
    color: #000;
    border-radius: 20px;
    font-size: 16px;
    font-family: "GothamPro-Medium";
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-benefits .list-values .benefit-item {
        padding: 8px 16px;
        font-size: 14px;
    }
}

.Certificate .price-table-wrap .row .cell.cell-price-value {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    color: #000;
}

.Certificate .price-table-wrap .row .cell.cell-price-value span {
    font-size: 25px;
    position: relative;
    top: -2px;
    margin-left: 5px;
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row .cell.cell-price-value {
        font-size: 20px;
    }

    .Certificate .price-table-wrap .row .cell.cell-price-value span {
        font-size: 20px;
        top: -1px;
    }
}

.Certificate .price-table-wrap .row .cell.green {
    background-color: #69de64;
}

.Certificate .price-table-wrap .row .cell.yellow {
    background-color: #ffe600;
}

.Certificate .price-table-wrap .row .cell.gray {
    background-color: #f5f5f5;
}

@media (max-width: 850px) {

    .Certificate .price-table-wrap .row .cell:nth-child(2),
    .Certificate .price-table-wrap .row .cell:nth-child(4) {
        border-left: none;
    }
}

@media (max-width: 850px) {
    .Certificate .price-table-wrap .row.row-head .cell {
        display: none;
    }
}
.Celebrate {
    min-height: calc(100vh - 194px);
}

.celebrate-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
}


.celebrate-offer {
    display: flex;
    margin-bottom: 60px;
    position: relative;
    padding: 10px 20px 0;
}

.celebrate-offer::before {
    content: '';
    width: 10px;
    height: 100%;
    background-color: #ffe60090;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}


.offer-photos {
    width: 45%;
}

.offer-main {
    width: 55%;
    display: flex;
    flex-direction: column;
}

.offer-title {
    font-size: 25px;
    font-family: "GothamPro-Bold";
    font-weight: normal;
}

.offer-description {
    display: flex;
    flex-direction: column;
    font-family: "GothamPro-Medium";
    font-size: 16px;
    line-height: 1.4;
    color: #00000090;
    margin-top: 20px;
}

.celebrate-block-intro {
    font-family: "GothamPro-Medium";
    font-size: 16px;
    line-height: 1.4;
    color: #00000090;
    margin: 20px 0 60px;
}

.offer-description ol { counter-reset: item; }
.offer-description ol li { display: block; }
.offer-description ol li:before {
    content: counter(item) ". ";
    counter-increment: item;
    color: #000;
    font-family: "GothamPro-Bold";
}

.offer-description ol {
    margin: 15px 0;
}

.offer-description .mb {
    margin-bottom: 15px;
}
.offer-description p span {
    color: #000;
    font-family: "GothamPro-Bold";
}

.offer-photos .RentImages {
    padding-left: 20px;
}

.offer-photos .RentImages .event-cell {
    width: calc(100%/3 - 20px);
    height: 80px;
    margin: 5px;
}

.offer-photos .RentImages .event-cell:nth-of-type(1) {
    margin-left: 35px;
}

.offer-photos .RentImages .event-cell:nth-of-type(7n) {
    margin-left: 35px;
}



@media (max-width: 600px){
    .celebrate-offer {
        flex-direction: column;
        padding: 10px 15px 0;
    }

    .celebrate-offer::before {
        width: 7px;
    }

    .offer-photos {
        width: 100%;
        margin-top: 8px;
    }
    
    .offer-main {
        width: 100%;
    }

    .offer-photos .RentImages .event-cell {
        width: calc(100%/3 - 6px);
        height: 60px;
        margin: 3px !important;
    }

    .offer-photos .RentImages {
        padding-left: 0;
    }   
    
    
}

.Footer {
    min-height: 100px;
    background-color: #000;
    padding: 5px;
}
.footer-main {
    padding: 10px;
    margin-bottom: 20px;
}
.footer-main .container {
    align-items: center;
    flex-direction: row;
}

.footer-extra {
    height: 40px;
    border-top: 1px solid #313131;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-extra p{
    color: #8a8a8a;
    font-size: 12px;
}

.footer-nav {
    width: 300px;
    display: flex;
    flex-wrap: wrap;
}

.footer-nav-item-wrap {
    width: 50%;
    padding: 5px 0;
}

.footer-nav-item {
    color: #8a8a8a;
    font-family: "GothamPro";
    font-size: 13px;
    padding: 3px 10px 0 0;
    margin: 5px 0;
    text-decoration: none;
    transition: 200ms;
}

.footer-nav-item:hover {
    color: #aaaaaa;
}

.footer-info {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.footer-logo {
    height: 50px;
}

.footer-logo img {
    height: 100%;
    opacity: 0.35;
    -webkit-user-select: none;
            user-select: none;
    -webkit-user-drag: none;
}

.footer-info-social {
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.footer-social-elem {
    height: 30px;
    margin-left: 15px;
}

.footer-social-elem:nth-child(1) {
    margin: 0;
}

.footer-social-elem a{
    color: #8a8a8a;
    height: 100%;
    width: 100%;
    font-size: 30px;
    transition: 200ms;
}

.footer-social-elem a:hover{
    color: #aaa;
}



@media only screen and (max-width: 665px){

    .footer-main .container {
        flex-direction: column;
    }

    .footer-nav {
        width: 100%;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .footer-nav-item-wrap {
        width: 100%;
    }

    .footer-info {
        margin-left: 0;
        width: 100%;
        flex-direction: column;
    }

    .footer-info-social {
        margin: 0 0 20px;
    }
}


@media only screen and (max-width: 425px){
    .footer-nav-item-wrap {
        display: flex;
        justify-content: center;
        padding: 0;
    }
}
.Grid {
    min-height: 100vh;
    padding: 15px 0 50px;
}

input {
    outline: none;
    border-radius: 0;
    box-shadow: 0;
    -webkit-border-radius:0px;
    -webkit-appearance: none;
}

.grid-overlay {
    width: 100%;
    padding: 10px;
}

.grid-overlay-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

}

.Grid .inputs_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

.Grid .input_wrap {
    display: flex;
    align-items: center;
    margin: 3px 0;
}

.Grid .input-label {
    font-size: 13px;
    font-family: "GothamPro-Medium";
    word-wrap: none;
}

.Grid .input-settings {
    margin-left: 10px;
    font-size: 13px;
    border: 2px solid #bdbdbd;
    width: 64px;
    overflow: hidden;
    background: #fff;
    text-align: center;
    padding: 4px 1px 4px 3px;

}

.select-wrap {
    border: 2px solid #bdbdbd;
    width: 64px;
    overflow: hidden;
    background: #fff;
    margin-left: 10px;
}

.Grid .select-settings {
    font-size: 13px;
    padding: 4px 6px 4px 27px;
    width: 100%;
    text-align: center;
    border: none;
    box-shadow: none;
    background-color: initial;
    background-image: none;
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
}

/* grid */

.grid-wrap {
    display: flex;
    margin: 0 auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 50px;
    transform-origin: (0%, 50%)
}

.grid-stage {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 10px;
    border-right: 2px dashed #00000020;
    position: relative;
}

.grid-stage:nth-child(1) {
    border-left: 2px dashed #00000020;
}

.grid-stage-list-wrap {
    display: flex;
    height: 100%;
}

.grid-stage-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 12px;
    height: 100%;
}

.grid-stage-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stage-title-num{
    text-align: center;
    font-size: 16px;
    font-family: "GothamPro-Medium";
    margin-bottom: 5px;
}

.stage-title-subline {
    text-align: center;
    font-size: 12px;
    color: #a7a7a7;
    margin-top: 2px;
    font-weight: 600;
    font-family: "GothamPro";
}


.grid-group {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.grid-driver-wrapper {
    display: flex;
    align-items: center;
    margin: 1.5px 0;
}

.grid-driver-num {
    font-size: 10px;
    font-family: "GothamPro-Medium";
    width: 10px;
    text-align: right;
    padding-right: 4px;
    color: #00000060;
}

.grid-driver {
    width: 150px;
    height: 30px;
    background-color: #f5f5f5;
    border: 1px solid #00000040;
    padding: 5px;
    font-size: 12px;
    font-family: "GothamPro-Medium";
    overflow: hidden;
}


.generate-pdf-wrap {
    display: flex;
    align-items: center
}

.generate-pdf {
    margin: 15px auto 0;
    font-size: 12px;
    padding: 7px 15px 7px;
    background-color: #ffe600;
    color: #000;
    border-radius: 5px;
    font-family: "GothamPro-Medium";
}

.generate-pdf span{
    font-size: 15px;
    margin: 0 4px 0 10px;
}

.pdf-preparing {
    z-index: 9999;
    min-height: 100vh;
    min-width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fffffff6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pdf-preparing p {
    font-size: 21px;
    font-family: "GothamPro-Bold" 
}

.pdf-preparing img {
    width: 50px;
    height: 50px;
}

@keyframes rotate360 {
  to {
    transform: rotate(360deg);
  }
}

.slider {
    height: 100vh;
    position: fixed;
    width: 0;
    top: 0;
    left: 0;
    background-color: #f2f2f2;
    z-index: 99999;
    transition: .2s ease-out;
    width: 100%;
    transform-origin: right;
    transform: scaleX(0);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-active {
    transform: scaleX(1);
  }
  
  .slider-spinner-wrap {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 10px #0000005d;
    transition: .1s ease-in-out;
    opacity: 0;
  }
  
  .slider-spinner-wrap img {
    width: 100%;
    animation: rotate360;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background-color: transparent;
  }
  
  .slider-spinner-wrap-active {
    opacity: 1;
  }
.WeekDriver {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.WeekDriver .container {
    max-width: 850px;
}

.WeekDriver .week-driver-heading {
    font-size: 30px;
    font-family: 'GothamPro-Bold';
    color: #000 70;
}

.WeekDriver .week-driver-info {
    font-size: 17px;
    margin-top: 30px;
    line-height: 1.3;
    margin-bottom: 30px;
}

.WeekDriver .week-driver-info p {
    margin-bottom: 15px;
}

.WeekDriver .week-driver-info p span {
    font-family: "GothamPro-Medium";
}

.WeekDriver .week-driver-next-race {
    display: flex;
    flex-direction: column;
    margin: 20px auto 40px 0;
}

.WeekDriver .week-driver-next-race .next-race-heading {
    font-size: 30px;
    font-family: 'GothamPro-Bold';
    color: #000 70;
    margin-bottom: 20px;
}

.WeekDriver .week-driver-next-race .next-race-main {
    display: flex;
    align-items: center;
    font-family: "GothamPro-Medium";
    font-size: 17px;
}

.WeekDriver .week-driver-next-race .next-race-main span {
    padding: 7px 15px 4px;
    background-color: #ffe600;
    margin-left: 10px;
}

.WeekDriver .week-driver-next-race .next-race-main a {
    color: #4470ff;
    text-decoration: underline;
    margin-left: 15px;
}

@media (max-width: 650px) {
    .WeekDriver .week-driver-next-race .next-race-main {
        flex-direction: column;
        align-items: flex-start;
    }

    .WeekDriver .week-driver-next-race .next-race-main span,
    .WeekDriver .week-driver-next-race .next-race-main a {
        margin-top: 10px;
        margin-left: 0;
    }
}

.WeekDriver .week-driver-past-races {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
}

.WeekDriver .week-driver-past-races .past-races-title {
    font-size: 30px;
    font-family: 'GothamPro-Bold';
    color: #000 70;
}

.WeekDriver .week-driver-past-races .past-races-list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.RaceItem {
    display: flex;
    flex-direction: column;
    padding: 25px;
    background-color: #fff;
    box-shadow: 0px 3px 10px 1px #d8d8d8;
    margin: 0 0 30px;
    border: 2px solid transparent;
    transition: 0.2s;
}

.RaceItem:hover {
    box-shadow: 0px 3px 12px 1px #cacaca;
    border-color: #4470ff;
}

.RaceItem .race-title {
    font-size: 23px;
    font-family: "GothamPro-Medium";
    color: #000;
}

.RaceItem .race-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.RaceItem .race-info .race-date {
    font-size: 16px;
    color: #000 60;
}

.RaceItem .race-info .race-detailed {
    font-size: 14px;
    color: #4470ff;
    font-family: "GothamPro-Medium";
    margin-left: 10px;
}

.RaceItem .race-info .race-detailed:hover {
    text-decoration: underline;
}

.RaceItem .race-places {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

@media (max-width: 650px) {
    .RaceItem .race-places {
        flex-direction: column;
        align-items: center;
    }

    .RaceItem .race-places .race-place {
        margin-bottom: 15px;
    }
}

.RaceItem .race-places .race-place {
    display: flex;
    align-items: center;
    width: calc(100%/3);
}

@media (max-width: 650px) {
    .RaceItem .race-places .race-place {
        margin-bottom: 25px;
        width: 100%;
    }
}

.RaceItem .race-places .race-place .race-place-position {
    height: 60px;
    position: relative;
}

@media (max-width: 650px) {
    .RaceItem .race-places .race-place .race-place-position {
        height: 75px;
        min-width: 105px;
        display: flex;
        justify-content: center;
    }
}

.RaceItem .race-places .race-place .race-place-position img {
    height: 100%;
    -webkit-user-drag: none;
    -webkit-user-select: none;
            user-select: none;
}

.RaceItem .race-places .race-place .race-place-driver {
    font-family: "GothamPro-Medium";
    color: #000;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.RaceItem .race-places .race-place .driver-name {
    font-size: 22px;
}

@media (max-width: 650px) {
    .RaceItem .race-places .race-place .driver-name {
        font-size: 18px;
    }
}

.RaceItem .race-places .race-place .driver-surname {
    font-size: 18px;
    color: #000 90;
}

@media (max-width: 650px) {
    .RaceItem .race-places .race-place .driver-surname {
        font-size: 16px;
    }
}
