.TopSectionServices {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    position: relative;
    margin-bottom: 30px;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.TopSectionServices .section-title {
    z-index: 11;
    display: inline-block;
    font-size: 30px;
    font-family: "GothamPro-Bold";
    text-align: center;
    background-color: #ffe600;
    padding: 10px 60px;
    color: #000;
}

@media (max-width: 480px){
    .TopSectionServices {
        background-size: auto 140%;
    }

    .TopSectionServices .section-title {
        font-size: 22px;
    }
    
}