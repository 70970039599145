@keyframes rotate360 {
  to {
    transform: rotate(360deg);
  }
}

.slider {
    height: 100vh;
    position: fixed;
    width: 0;
    top: 0;
    left: 0;
    background-color: #f2f2f2;
    z-index: 99999;
    transition: .2s ease-out;
    width: 100%;
    transform-origin: right;
    transform: scaleX(0);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-active {
    transform: scaleX(1);
  }
  
  .slider-spinner-wrap {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 10px #0000005d;
    transition: .1s ease-in-out;
    opacity: 0;
  }
  
  .slider-spinner-wrap img {
    width: 100%;
    animation: rotate360;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background-color: transparent;
  }
  
  .slider-spinner-wrap-active {
    opacity: 1;
  }