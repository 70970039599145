.RaceItem {
    display: flex;
    flex-direction: column;
    padding: 25px;
    background-color: #fff;
    box-shadow: 0px 3px 10px 1px #d8d8d8;
    margin: 0 0 30px;
    border: 2px solid transparent;
    transition: 0.2s;
}

.RaceItem:hover {
    box-shadow: 0px 3px 12px 1px #cacaca;
    border-color: #4470ff;
}

.RaceItem .race-title {
    font-size: 23px;
    font-family: "GothamPro-Medium";
    color: #000;
}

.RaceItem .race-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.RaceItem .race-info .race-date {
    font-size: 16px;
    color: #000 60;
}

.RaceItem .race-info .race-detailed {
    font-size: 14px;
    color: #4470ff;
    font-family: "GothamPro-Medium";
    margin-left: 10px;
}

.RaceItem .race-info .race-detailed:hover {
    text-decoration: underline;
}

.RaceItem .race-places {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

@media (max-width: 650px) {
    .RaceItem .race-places {
        flex-direction: column;
        align-items: center;
    }

    .RaceItem .race-places .race-place {
        margin-bottom: 15px;
    }
}

.RaceItem .race-places .race-place {
    display: flex;
    align-items: center;
    width: calc(100%/3);
}

@media (max-width: 650px) {
    .RaceItem .race-places .race-place {
        margin-bottom: 25px;
        width: 100%;
    }
}

.RaceItem .race-places .race-place .race-place-position {
    height: 60px;
    position: relative;
}

@media (max-width: 650px) {
    .RaceItem .race-places .race-place .race-place-position {
        height: 75px;
        min-width: 105px;
        display: flex;
        justify-content: center;
    }
}

.RaceItem .race-places .race-place .race-place-position img {
    height: 100%;
    -webkit-user-drag: none;
    user-select: none;
}

.RaceItem .race-places .race-place .race-place-driver {
    font-family: "GothamPro-Medium";
    color: #000;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.RaceItem .race-places .race-place .driver-name {
    font-size: 22px;
}

@media (max-width: 650px) {
    .RaceItem .race-places .race-place .driver-name {
        font-size: 18px;
    }
}

.RaceItem .race-places .race-place .driver-surname {
    font-size: 18px;
    color: #000 90;
}

@media (max-width: 650px) {
    .RaceItem .race-places .race-place .driver-surname {
        font-size: 16px;
    }
}