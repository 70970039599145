.EventImages {
    height: 100%;
    display: -webkit-flex; 
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.ril__caption, .ril__toolbar {
    background-color: transparent !important;
}
.event-cell-wrap{

    width: 20%;
    padding: 5px;
}
.event-cell {
    width: 100%;
    height: 150px;
}

.event-img-cell {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: transform 300ms;
    cursor: pointer;
    background-image: url(../img/preloader.gif);
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;
}

.event-img-cell:hover img {
    transform: translate(-50%, -50%) scale(1.07);
}

.event-img-cell img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: 300ms;
    background-color: transparent;
}

.event-img-cell:hover {
    z-index: 1;
    transform: scale(1.03);
    box-shadow: 0px 4px 15px #00000090;
    outline: 3px solid #ffe600;
}

.event-img-cell iframe {
    height: 100%;
    width: 100%;
    border: none;
}

@media only screen and (max-width: 1024px){
    .event-cell-wrap{
        width: 25%;
    }    
}
@media only screen and (max-width: 880px){
    .event-cell-wrap{
        width: 33.33%;
    }    
}
@media only screen and (max-width: 670px){
    .event-cell-wrap{
        width: 50%;
    }    
}
