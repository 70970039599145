.error-page {
    z-index: auto;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #00000085;
}

.error-page h2 {
    font-family: "GothamPro-Bold";
    font-size: 350px;
    font-weight: bolder;
    color: #00000046;
    margin-bottom: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.error-page h3 {
    text-align: center;
    color: #fff;
    font-size: 40px;
    line-height: 1.2;
}

.error-page a {
    margin-top: 40px;
    padding: 15px 50px;
    background-color: #ffe600;
    text-decoration: none;
    font-size: 25px;
    color: #000;
    font-family: "GothamPro-Medium";
}
.error-page a span {
    display: block;
    transition: 200ms;
}   


.error-page a:hover span {
    transform: scale(1.05);
}



video::-webkit-media-controls {
    display: none;
    pointer-events: none;
}
video::-webkit-media-controls-start-playback-button {
    display: none;
    pointer-events: none;
}


.video_container {
    z-index: -2;
    width: 100%;
    height: 100%; 
    overflow: hidden;
    position: absolute;
    background-image: url(../img/bgtop.jpg);
    background-size: cover;
    pointer-events: none;
}

.video_container video {
    z-index: -2;
    min-width: 100%; 
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none;
}



@media only screen and (max-width:425px){
    .error-page h2 {
        display: none;
    }
}