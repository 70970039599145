.Grid {
    min-height: 100vh;
    padding: 15px 0 50px;
}

input {
    outline: none;
    border-radius: 0;
    box-shadow: 0;
    -webkit-border-radius:0px;
    -webkit-appearance: none;
}

.grid-overlay {
    width: 100%;
    padding: 10px;
}

.grid-overlay-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

}

.Grid .inputs_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: fit-content;
    margin: auto;
}

.Grid .input_wrap {
    display: flex;
    align-items: center;
    margin: 3px 0;
}

.Grid .input-label {
    font-size: 13px;
    font-family: "GothamPro-Medium";
    word-wrap: none;
}

.Grid .input-settings {
    margin-left: 10px;
    font-size: 13px;
    border: 2px solid #bdbdbd;
    width: 64px;
    overflow: hidden;
    background: #fff;
    text-align: center;
    padding: 4px 1px 4px 3px;

}

.select-wrap {
    border: 2px solid #bdbdbd;
    width: 64px;
    overflow: hidden;
    background: #fff;
    margin-left: 10px;
}

.Grid .select-settings {
    font-size: 13px;
    padding: 4px 6px 4px 27px;
    width: 100%;
    text-align: center;
    border: none;
    box-shadow: none;
    background-color: initial;
    background-image: none;
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
}

/* grid */

.grid-wrap {
    display: flex;
    margin: 0 auto;
    width: fit-content;
    margin-top: 50px;
    transform-origin: (0%, 50%)
}

.grid-stage {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 10px;
    border-right: 2px dashed #00000020;
    position: relative;
}

.grid-stage:nth-child(1) {
    border-left: 2px dashed #00000020;
}

.grid-stage-list-wrap {
    display: flex;
    height: 100%;
}

.grid-stage-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 12px;
    height: 100%;
}

.grid-stage-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stage-title-num{
    text-align: center;
    font-size: 16px;
    font-family: "GothamPro-Medium";
    margin-bottom: 5px;
}

.stage-title-subline {
    text-align: center;
    font-size: 12px;
    color: #a7a7a7;
    margin-top: 2px;
    font-weight: 600;
    font-family: "GothamPro";
}


.grid-group {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.grid-driver-wrapper {
    display: flex;
    align-items: center;
    margin: 1.5px 0;
}

.grid-driver-num {
    font-size: 10px;
    font-family: "GothamPro-Medium";
    width: 10px;
    text-align: right;
    padding-right: 4px;
    color: #00000060;
}

.grid-driver {
    width: 150px;
    height: 30px;
    background-color: #f5f5f5;
    border: 1px solid #00000040;
    padding: 5px;
    font-size: 12px;
    font-family: "GothamPro-Medium";
    overflow: hidden;
}


.generate-pdf-wrap {
    display: flex;
    align-items: center
}

.generate-pdf {
    margin: 15px auto 0;
    font-size: 12px;
    padding: 7px 15px 7px;
    background-color: #ffe600;
    color: #000;
    border-radius: 5px;
    font-family: "GothamPro-Medium";
}

.generate-pdf span{
    font-size: 15px;
    margin: 0 4px 0 10px;
}

.pdf-preparing {
    z-index: 9999;
    min-height: 100vh;
    min-width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fffffff6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pdf-preparing p {
    font-size: 21px;
    font-family: "GothamPro-Bold" 
}

.pdf-preparing img {
    width: 50px;
    height: 50px;
}
