@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Header {
    position: fixed;
    z-index: 999;
    width: 100%;
}

.menu {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    padding: 10px;
    position: absolute;
    width: 100%;
    height: 100vh;
    opacity: 0;
}

.menu-blur {
    transition: 500ms;
    display: flex;
    background-color: #000000ec;
    background-repeat: repeat;
    animation: fadeIn 50ms linear forwards;
    height: 100vh;
    width: 100%;

}

.menu .navigation{
    display: none;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.navigation-active {
    opacity: 0;
    display: flex !important;
    animation: fadeIn 400ms linear forwards 200ms;
}

.menu_button {
    position: absolute;
    top: 10px;
    left: 15px;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 9999999;
    font-size: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 5px 0px;
    width: fit-content;
    text-decoration: none;
    transition: 200ms;
}

.menu_button-color {
    color: #000;
}

.menu_button:hover {
    transform: scale(1.1);
}

.menu .navigation ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: -wekit-flex;
    -webkit-flex-direction: column;
    -webkit-justify-content: flex-start;
    -webkit-align-items: center;
}

.menu .navigation ul li {
    opacity: 0;
    width: 100%;
    max-width: 350px;
    list-style: none;
    text-align: center;
    display: flex;
    margin: 15px 15px;
    animation: fadeIn 200ms linear forwards;
    background-color: #ffe600;
    transition: 100ms;
}

.menu .navigation ul li:nth-child(1) {
    transform: rotate(2deg);   
}
.menu .navigation ul li:nth-child(2) {
    animation-delay: 100ms;
    transform: rotate(-1.5deg) translateX(-10px);

}
.menu .navigation ul li:nth-child(3) {
    animation-delay: 150ms;
    transform: rotate(1deg) translateX(10px);
}
.menu .navigation ul li:nth-child(4) {
    animation-delay: 200ms;
    transform: rotate(-2deg) translateX(-10px);    
}
.menu .navigation ul li:nth-child(5) {
    animation-delay: 250ms;
    transform: rotate(1deg);    
}
.menu .navigation ul li:nth-child(6) {
    animation-delay: 300ms;
    transform: rotate(-1deg) translateX(-8px);  
}
.menu .navigation ul li:nth-child(7) {
    animation-delay: 300ms;
    transform: rotate(1deg) translateX(-5px);  
}


.menu .navigation ul li:nth-child(1):hover {
    transform: rotate(2deg) scale(1.03);   
}
.menu .navigation ul li:nth-child(2):hover {
    transform: rotate(-1.5deg) translateX(-10px) scale(1.03);
}
.menu .navigation ul li:nth-child(3):hover {
    transform: rotate(1deg) translateX(10px) scale(1.03);
}
.menu .navigation ul li:nth-child(4):hover {
    transform: rotate(-2deg) translateX(-10px) scale(1.03);    
}
.menu .navigation ul li:nth-child(5):hover {
    transform: rotate(1deg) scale(1.03);    
}
.menu .navigation ul li:nth-child(6):hover {
    transform: rotate(-1deg) translateX(-8px) scale(1.03);    
}
.menu .navigation ul li:nth-child(7):hover {
    transform: rotate(1deg) translateX(-5px) scale(1.03);    
}

.menu .navigation ul li a{
    width: 100%;
    height: 100%;
    font-size: 30px;
    font-family: "GothamPro-Medium";
    padding: 12px 0;
    text-decoration: none;
    color: #000;
    transition: 100ms;
}

.menu .navigation ul li a:hover{
    transform: scale(1.03) !important;
}

.menu .navigation ul li:hover a{
    transform: scale(1.03);
}



@media only screen and (max-width: 500px){

    .Home .container .logo img {
        width: 100% !important;
        max-width: 500px !important;
    }

    .menu .navigation {
        justify-content: flex-start;
    }

    .menu .navigation ul {
        margin-top: 50px;
    }

    .menu .navigation ul li {
        width: 270px;
        margin: 10px 0;
    }

    .menu .navigation ul li a {
        font-size: 22px;
        padding: 17px;
    }


    
}


@media only screen and (max-height: 600px) and (max-width: 820px) {

    .Home .container .logo img {
        width: 100% !important;
        max-width: 500px !important;
    }

    .menu {
        overflow-y: scroll;
    }

    .menu .navigation {
        justify-content: flex-start;
    }

    .menu .navigation ul {
        min-height: calc(100vh + 100px);
        margin: 70px 0 0 0;
    }

    .menu .navigation ul li {
        min-height: 50px;
        width: 270px;
        margin: 10px 0;
    }
    
    .menu .navigation ul li a {
        font-size: 22px;
        padding: 15px 0;
    }

}


@media only screen and (max-height: 330px) and (max-width: 820px) {

    .menu .navigation ul {
        min-height: 600px;
        margin: 70px 0 100px 0;
    }

}