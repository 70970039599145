.Price {
    margin-bottom: 100px;
}

.Price .price-table-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    position: relative;
}

.Price .price-table-wrap .row {
    display: flex;
    border-bottom: 6px solid #fff;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row {
        flex-wrap: wrap;
        border: none;
    }

    .Price .price-table-wrap .row:not(.row-head) {
        margin-bottom: 40px;
    }
}

@media (min-width: 851px) {
    .Price .price-table-wrap .row:not(.row-head) .cell-price-head {
        display: none;
    }
}

.Price .price-table-wrap .row .cell {
    padding: 20px 30px;
    position: relative;
    font-family: "GothamPro-Bold";
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell {
        padding: 15px 20px;
    }
}

.Price .price-table-wrap .row .cell img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}

.Price .price-table-wrap .row .cell img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
}

.Price .price-table-wrap .row .cell.cell-title {
    width: 50%;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-title {
        width: 100%;
    }
}

.Price .price-table-wrap .row .cell.cell-title p {
    z-index: 3;
    position: relative;
    margin-top: 60px;
    font-size: 33px;
    font-family: "GothamPro-Bold";
    color: #fff;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-title p {
        font-size: 28px;
    }
}

.Price .price-table-wrap .row .cell.cell-title .duration {
    position: relative;
    font-size: 16px;
    font-family: "GothamPro-Medium";
    background-color: #ffe600;
    padding: 5px 15px;
    color: #000;
    z-index: 3;
    width: fit-content;
    margin-top: 15px;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-title .duration {
        font-size: 14px;
        margin-top: 10px;
    }
}

.Price .price-table-wrap .row .cell.cell-title.gradient::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.95) 100%);
    z-index: 2;
}

.Price .price-table-wrap .row .cell.cell-price {
    width: 25%;
    border-left: 6px solid #fff;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-price {
        width: 50%;
    }
}

.Price .price-table-wrap .row .cell.cell-price-head {
    font-size: 20px;
    color: #000;
    text-align: center;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-price-head {
        border-bottom: 6px solid #fff;
        font-size: 20px;
    }
}

.Price .price-table-wrap .row .cell.cell-price-value {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    color: #000;
}

.Price .price-table-wrap .row .cell.cell-price-value span {
    font-size: 25px;
    position: relative;
    top: -2px;
    margin-left: 5px;
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row .cell.cell-price-value {
        width: 50%;
        font-size: 20px;
    }

    .Price .price-table-wrap .row .cell.cell-price-value span {
        font-size: 20px;
        top: -1px;
    }
}

.Price .price-table-wrap .row .cell.green {
    background-color: #69de64;
}

.Price .price-table-wrap .row .cell.yellow {
    background-color: #ffe600;
}

.Price .price-table-wrap .row .cell.gray {
    background-color: #f0f0f0;
}

@media (max-width: 850px) {

    .Price .price-table-wrap .row .cell:nth-child(2),
    .Price .price-table-wrap .row .cell:nth-child(4) {
        border-left: none;
    }
}

@media (max-width: 850px) {
    .Price .price-table-wrap .row.row-head .cell {
        display: none;
    }
}