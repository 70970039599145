.Affiche {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 30px;
    box-shadow: 0 5px 17px rgba(0, 0, 0, 0.089);
}

.affiche-content {
    display: flex;
    min-height: 220px;
}

.affiche-date {
    min-width: 170px;
    width: 170px;
    padding: 20px 15px;
    background-color: #ffe600;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.affiche-date-day {
    font-family: "GothamPro-Bold";
    font-size: 40px;
    letter-spacing: 2px;
    margin: 0 0 5px;
    color: #11111190;
}

.affiche-date-month {
    font-size: 20px;
    margin: 0 0 5px;
    color: #11111190;
    font-family: "GothamPro-Medium";
}

.affiche-days-to {
    margin-top: auto;
    color: #11111190;
    font-size: 15px;
}

.affiche-soon {
    padding: 7px 12px;
    background-color: #f04a4a;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
}

.affiche-main {
    padding: 15px;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.affiche-thumb {
    min-width: 150px;
    width: 150px;
}

.affiche-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.affiche-title {
    font-family: "GothamPro-Medium";
    font-size: 22px;
    margin-bottom: 11px;
}

.affiche-description {
    font-family: "GothamPro-Medium";
    font-size: 15px;
    color: #888888;
    max-width: 600px;
    line-height: 1.3;
}

.extra-info {
    margin-top: auto;
    padding-top: 20px;
}

.extra-info-row {
    display: flex;
    font-family: "GothamPro-Medium";
    font-size: 15px;
    color: #000000;
    max-width: 600px;
    line-height: 1.3;
    margin-bottom: 5px;
}

.extra-info-row-fee {
    margin-top: 10px;
    color: #888888;
}

.row-label {
    margin-right: 20px;
    width: 100px
}

.row-value a {
    color: #2e87ec;
}

.affiche-img {
    max-width: 300px;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
}

.affiche-img img {
    height: 100%;
}

.affiche-links {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.affiche-link {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.link-label {
    font-family: "GothamPro-Medium";
    margin-right: 7px;
    color: #888;
    font-size: 15px;
}

.link-value {
    font-family: "GothamPro-Medium";
    color: #2e87ec;
    font-size: 15px;
    transition: 100ms;
}

.link-value:hover {
    color: #5ca8ff;
}





@media (max-width: 700px){
    .Affiche {
        height: auto;
        min-height: 250px;
    }

    .affiche-content {
        flex-direction: column;
        position: relative;
    }

    .affiche-date-day {
        font-size: 45px;
    }

    .affiche-date {
        width: 100%;
    }

    .affiche-date.has-thumb {
        height: 200px;
        padding-right: 150px;
    }

    .affiche-thumb {
        position: absolute;
        height: 200px;
        width: 135px;
        min-width: 135px;
        right: 0;
    }
   
}