.ServiceCard-main {
    margin: 0 0 70px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (max-width: 500px) {
    .ServiceCard-main {
        margin: 0 0 50px 0;
    }
}

.ServiceCard-main .ServiceCard-wrap {
    display: flex;
    position: relative;
    padding: 80px 100px;
    width: 100%;
    background-size: cover;
    background-position: center;
}

@media (max-width: 1024px) {
    .ServiceCard-main .ServiceCard-wrap {
        padding: 30px 60px 80px 60px;
    }
}

@media (max-width: 500px) {
    .ServiceCard-main .ServiceCard-wrap {
        padding: 30px 20px 80px 20px;
    }
}

.ServiceCard-main .ServiceCard-wrap.service-item-1 {
    background-image: url('../../img/services/service-img-1.jpg');
}

.ServiceCard-main .ServiceCard-wrap.service-item-2 {
    background-image: url('../../img/services/service-img-2.jpg');
}

.ServiceCard-main .ServiceCard-wrap.service-item-3 {
    background-image: url('../../img/services/service-img-3.jpg');
}

.ServiceCard-main .ServiceCard-wrap.service-item-4 {
    background-image: url('../../img/services/service-img-4.jpg');
}

.ServiceCard-main .ServiceCard-wrap.service-item-5 {
    background-image: url('../../img/services/service-img-5.jpg');
}

.ServiceCard-main .ServiceCard-wrap:after,
.ServiceCard-main .ServiceCard-wrap:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.1s opacity;
}

.ServiceCard-main .ServiceCard-wrap:after {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.95) 100%);
    opacity: 1;
}

.ServiceCard-main .ServiceCard-wrap:hover:after {
    opacity: 0.7;
}

.ServiceCard-main .ServiceCard-wrap:hover .ServiceCard::after {
    transform: scale(1.1);
    right: 0;
}

.ServiceCard-main .ServiceCard-wrap:hover .ServiceCard-title {
    transform: scale(1.05) rotate(-0.5deg);
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard:after {
    content: '';
    background-image: url('data:image/svg+xml; base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMzUuMjggMjMxLjAyIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48dGl0bGU+QXNzZXQgMTwvdGl0bGU+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNTEuNjMsMCwxMTguOSwzNS4xM2w1Miw1NS44NkgwVjEzOS45SDE3MS4wNmwtNTIuMTYsNTZMMTUxLjYzLDIzMWw4My42NS04OS44M1Y4OS43OVoiLz48L3N2Zz4=');
    background-size: 100%;
    width: 50px;
    height: 50px;
    background-position: center;
    position: absolute;
    top: 0;
    right: 10px;
    transition: 0.15s;
}

@media (max-width: 1024px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard:after {
        top: unset;
        bottom: -40px;
        width: 80px;
        height: 80px;
        opacity: 0.3;
    }
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-title {
    font-size: 25px;
    font-family: "GothamPro-Bold";
    text-align: left;
    line-height: 1.2;
    background-color: #ffe600;
    padding: 10px 60px;
    color: #000;
    transition: 0.15s;
    z-index: 1;
}

@media (max-width: 500px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-title {
        padding: 10px;
        font-size: 20px;
    }
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-description {
    font-family: "GothamPro-Medium";
    line-height: 1.4;
    margin-top: 50px;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}

@media (min-width: 1025px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-description {
        width: 70%;
    }
}

@media (max-width: 500px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-description {
        font-size: 16px;
        margin-top: 25px;
    }
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-icon {
    position: absolute;
    left: -80px;
    top: -150px;
    width: 200px;
    height: 200px;
    pointer-events: none;
    transition: 0.1s;
}

@media (max-width: 1024px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-icon {
        top: -100px;
    }
}

@media (max-width: 500px) {
    .ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-icon {
        top: -90px;
        left: -40px;
        width: 150px;
        height: 150px;
    }
}

.ServiceCard-main .ServiceCard-wrap .ServiceCard .ServiceCard-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}