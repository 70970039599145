.Celebrate {
    min-height: calc(100vh - 194px);
}

.celebrate-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
}


.celebrate-offer {
    display: flex;
    margin-bottom: 60px;
    position: relative;
    padding: 10px 20px 0;
}

.celebrate-offer::before {
    content: '';
    width: 10px;
    height: 100%;
    background-color: #ffe60090;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}


.offer-photos {
    width: 45%;
}

.offer-main {
    width: 55%;
    display: flex;
    flex-direction: column;
}

.offer-title {
    font-size: 25px;
    font-family: "GothamPro-Bold";
    font-weight: normal;
}

.offer-description {
    display: flex;
    flex-direction: column;
    font-family: "GothamPro-Medium";
    font-size: 16px;
    line-height: 1.4;
    color: #00000090;
    margin-top: 20px;
}

.celebrate-block-intro {
    font-family: "GothamPro-Medium";
    font-size: 16px;
    line-height: 1.4;
    color: #00000090;
    margin: 20px 0 60px;
}

.offer-description ol { counter-reset: item; }
.offer-description ol li { display: block; }
.offer-description ol li:before {
    content: counter(item) ". ";
    counter-increment: item;
    color: #000;
    font-family: "GothamPro-Bold";
}

.offer-description ol {
    margin: 15px 0;
}

.offer-description .mb {
    margin-bottom: 15px;
}
.offer-description p span {
    color: #000;
    font-family: "GothamPro-Bold";
}

.offer-photos .RentImages {
    padding-left: 20px;
}

.offer-photos .RentImages .event-cell {
    width: calc(100%/3 - 20px);
    height: 80px;
    margin: 5px;
}

.offer-photos .RentImages .event-cell:nth-of-type(1) {
    margin-left: 35px;
}

.offer-photos .RentImages .event-cell:nth-of-type(7n) {
    margin-left: 35px;
}



@media (max-width: 600px){
    .celebrate-offer {
        flex-direction: column;
        padding: 10px 15px 0;
    }

    .celebrate-offer::before {
        width: 7px;
    }

    .offer-photos {
        width: 100%;
        margin-top: 8px;
    }
    
    .offer-main {
        width: 100%;
    }

    .offer-photos .RentImages .event-cell {
        width: calc(100%/3 - 6px);
        height: 60px;
        margin: 3px !important;
    }

    .offer-photos .RentImages {
        padding-left: 0;
    }   
    
    
}
