.Contacts {
    margin: 0 100px 50px;
}

.contacts-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
    margin-top: 100px;
    font-family: "GothamPro-Bold"
}

.contacts-main a {
    width: fit-content;
    text-decoration: none;
}

.contacts-worktime-day {
    display: flex;
    flex-direction: column;
}

.contacts-worktime-day span:nth-child(2) {
    font-size: 16px;
    margin-top: 5px;
}

.contacts-worktime-time {
    font-size: 20px;
    padding: 10px 25px;
    background-color: #ffe600;
    display: -webkit-inline-box;
    margin-top: 13px;
    transform: rotate(-1deg);

}

.contacts-address, .contacts-digit, .contacts-worktime {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
}

.contacts-address-title, .contacts-digit-title, .contacts-worktime-title {
    color: #bdbdbd;
    font-size: 20px;
    margin-bottom: 10px;
}

.contacts-address-value, .contacts-digit-value, .contacts-worktime-day {
    line-height: 1.2;
    color: #000;
    font-size: 25px;
    margin-bottom: 5px;
}

.contacts-address-value span {
    font-size: 16px;
}

.contacts-digit-value {
    font-size: 22px;
} 

.contacts-address-link {
    color: #000;
    font-size: 20px;
    padding: 10px 25px;
    background-color: #ffe600;
    margin-top: 10px;
    transform: rotate(2deg);
    transition: 100ms;
}

.contacts-address-link:hover {
    transform: scale(1.05) rotate(1deg);
}


@media only screen and (max-width: 425px) {
    .Contacts {
        margin: 0 0 50px;
    }
}