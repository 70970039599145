.EventThumb {
    width: 50%;
    padding: 15px;
}

.event_thumb-box {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
}


.event_thumb-box:hover .event_thumb-box-img{
    transform: translate(-50%, -50%) scale(1.04);
    opacity: 0.9;
}

.event_thumb-box-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 400ms ease-out;
    background-color: #000;
}

.event_thumb-box-img-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 400ms ease-out;
    opacity: 0
}

.event_thumb-box .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.event_thumb-title {
    position: relative;
    z-index: 10;
    padding: 10px 20px 8px;
    background-color: #ffe600;
    font-family: "GothamPro-Bold";
    font-size: 22px;
    width: fit-content;
    box-shadow: 2px 2px 7px #00000090;
    margin: 10px;
    color: #000;
}

.event_thumb-date {
    position: relative;
    z-index: 10;
    font-family: "GothamPro-Medium";
    font-size: 17px;
    width: fit-content;
    color: #fff;
    margin: 10px;
    text-shadow: 1px 1px 4px #00000096;
}

.event_thumb-link {
    z-index: 10;
    padding: 8px;
    border: 2px solid #fff;
    font-family: "GothamPro-Medium";
    font-size: 15px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    width: 100px;
    box-shadow: 2px 2px 7px #00000090;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #00000040;
}

.event_thumb-link:hover {
    background-color: #00c2f3d0;
}



@media only screen and (max-width: 850px){
    .EventThumb {
        width: 100%;
        padding: 15px;
    }
}

@media only screen and (max-width: 425px) {
    .event_thumb-box-img {
        width: auto;
        height: 100%;
    }
    
}