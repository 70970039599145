img {
    user-select: none;
}

body {
    font-family: "GothamPro";
    position: relative;
    min-height: 100vh;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    height: 100%;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

button {
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.content {
    height: 100%;
    min-height: calc(100vh - 190px);
}

.title {
    display: inline-block;
    font-size: 30px;
    font-family: "GothamPro-Bold";
    text-align: center;
    margin: 70px auto 40px;
    background-color: #ffe600;
    padding: 10px 60px;
    color: #000;
}

@media only screen and (max-width: 425px) {
    .title {
        font-size: 30px;
        padding: 10px 30px;
    }
}

.preloader {
    width: 100px;
    height: 100px;
}

.back-btn {
    position: fixed;
    top: 19px;
    left: 70px;
    z-index: 20;
    transition: 200ms;
}

@media (max-width: 480px) {
    .back-btn {
        display: none;
    }
}

.back-btn a {
    font-size: 16px;
    font-family: "GothamPro-Medium";
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 7px 15px;
}

.back-btn a i {
    position: relative;
    margin-right: 8px;
    font-size: 20px;
    left: 0;
    transition: 200ms;
}

.back-btn:hover {
    background-color: #fff;
}

.back-btn:hover i {
    left: -3px;
}

.disclamer {
    display: flex;
    padding: 10px 25px;
    background-color: #f5f5f5;
    color: #000;
    font-family: "GothamPro-Medium";
    font-size: 18px;
    margin: 15px 0;
    position: relative;
    line-height: 1.3;
}

@media (max-width: 850px) {
    .disclamer {
        font-size: 16px;
    }
}

.disclamer:before {
    content: '';
    font-family: "GothamPro-Bold";
    position: absolute;
    top: -8px;
    left: -8px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZTYwMDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPkFzc2V0IDE8L3RpdGxlPjxyZWN0IGNsYXNzPSJhIiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHJ4PSIyNSIvPjxwYXRoIGQ9Ik0yMS4xLDE2LjUxYTQuNTEsNC41MSwwLDAsMSwwLTYuNzdBNS41Nyw1LjU3LDAsMCwxLDI1LDguMzVhNS41Myw1LjUzLDAsMCwxLDMuOSwxLjM5LDQuNTMsNC41MywwLDAsMSwwLDYuNzcsNS41Myw1LjUzLDAsMCwxLTMuOSwxLjRBNS41OCw1LjU4LDAsMCwxLDIxLjEsMTYuNTFaTTIwLDQxLjY1VjIwLjM2SDMwVjQxLjY1WiIvPjwvc3ZnPg==");

}