.Footer {
    min-height: 100px;
    background-color: #000;
    padding: 5px;
}
.footer-main {
    padding: 10px;
    margin-bottom: 20px;
}
.footer-main .container {
    align-items: center;
    flex-direction: row;
}

.footer-extra {
    height: 40px;
    border-top: 1px solid #313131;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-extra p{
    color: #8a8a8a;
    font-size: 12px;
}

.footer-nav {
    width: 300px;
    display: flex;
    flex-wrap: wrap;
}

.footer-nav-item-wrap {
    width: 50%;
    padding: 5px 0;
}

.footer-nav-item {
    color: #8a8a8a;
    font-family: "GothamPro";
    font-size: 13px;
    padding: 3px 10px 0 0;
    margin: 5px 0;
    text-decoration: none;
    transition: 200ms;
}

.footer-nav-item:hover {
    color: #aaaaaa;
}

.footer-info {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.footer-logo {
    height: 50px;
}

.footer-logo img {
    height: 100%;
    opacity: 0.35;
    user-select: none;
    -webkit-user-drag: none;
}

.footer-info-social {
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.footer-social-elem {
    height: 30px;
    margin-left: 15px;
}

.footer-social-elem:nth-child(1) {
    margin: 0;
}

.footer-social-elem a{
    color: #8a8a8a;
    height: 100%;
    width: 100%;
    font-size: 30px;
    transition: 200ms;
}

.footer-social-elem a:hover{
    color: #aaa;
}



@media only screen and (max-width: 665px){

    .footer-main .container {
        flex-direction: column;
    }

    .footer-nav {
        width: 100%;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .footer-nav-item-wrap {
        width: 100%;
    }

    .footer-info {
        margin-left: 0;
        width: 100%;
        flex-direction: column;
    }

    .footer-info-social {
        margin: 0 0 20px;
    }
}


@media only screen and (max-width: 425px){
    .footer-nav-item-wrap {
        display: flex;
        justify-content: center;
        padding: 0;
    }
}