.rent-section {
    margin-bottom: 110px;
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
}

.rent-about {
    margin-top: 30px;
}


.rent-subtitle {
    font-family: "GothamPro-Medium";
    font-size: 35px;
    color: #bdbdbd;
    margin-bottom: 25px;
}

.rent-about-descr {
    font-family: "GothamPro-Medium";
    font-size: 20px;
    line-height: 1.45;
}

.rent-for-row {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.rent-for-row-mark {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #ffe600;
}

.rent-for-row-item {
    font-family: "GothamPro-Medium";
    font-size: 20px;
    margin-left: 10px;
}

.rent-min-item {
    font-family: "GothamPro-Medium";
    font-size: 20px;
    margin: 4px 0;
    line-height: 1.3
}

.Rent sup {
    font-size: 12px;
    vertical-align: top; 
    position: relative; 
}

.rent-min-item span {
    color: #bdbdbd;
    margin-right: 10px; 
}





.rent-example-item:last-child {
    margin: 0 !important;
}
.rent-example-item {
    display: flex;
    margin-bottom: 60px;
}

.rent-example-item__info {
    display: flex;
    flex-direction: column;
    width: 350px;
}

.rent-example-item__info__name {
    color: #000;
    font-size: 20px;
    background-color: #ffe600;
    padding: 10px 20px;
    width: fit-content;
    font-family: "GothamPro-Medium";
    margin: 5px 0 20px;
}

.rent-example-item-li {
    font-size: 18px;
    color: #000;
    font-family: "GothamPro-Medium";
    margin-bottom: 7px;
}
.rent-example-item-li span{
    color: #9e9e9e;
    margin-right: 15px;
}

.rent-example-item-descr {
    font-family: "GothamPro-Medium";
    margin-top: 20px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 1.3;
    color: #9e9e9e;
}



.rent-estimation-row {
    display: flex;
    align-items: center;
    margin: 4px 0;
}

.rent-estimation-row-title {
    font-family: "GothamPro-Medium";
    font-size: 20px;
    width: 230px;
}

.select-style {
    border: 2px solid #bdbdbd;
    width: 75px;
    overflow: hidden;
    background: #fff url("../../img/select_icon.png") no-repeat 90% 50%;
}

.select-style select {
    font-family: "GothamPro-Medium";
    font-size: 20px;
    padding: 8px 15px 7px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    cursor: pointer;
}

.select-style select:focus {
    outline: none;
}

.rent-estimation-result {
    margin-top: 20px;
    font-family: "GothamPro-Medium";
    font-size: 25px;
    padding: 10px 20px;
    background-color: #ffe600;
}

.rent-estimation-comment {
    font-family: "GothamPro-Medium";
    font-size: 18px;
    color: #bdbdbd;
    max-width: 500px;
    margin-top: 20px;
    line-height: 1.2
}





@media only screen and (max-width: 600px) {

    .rent-example-item__info {
        width: 100%;
    }

    .rent-example-item {
        flex-direction: column;
    }
}