
.RentImages {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
}

.RentImages .event-cell {
    width: calc(50% - 10px);
    height: 120px;
    margin: 5px;
}


@media only screen and (max-width: 800px){
    .RentImages .event-cell {
        width: 100%;
    }
     
}