.WeekDriver {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.WeekDriver .container {
    max-width: 850px;
}

.WeekDriver .week-driver-heading {
    font-size: 30px;
    font-family: 'GothamPro-Bold';
    color: #000 70;
}

.WeekDriver .week-driver-info {
    font-size: 17px;
    margin-top: 30px;
    line-height: 1.3;
    margin-bottom: 30px;
}

.WeekDriver .week-driver-info p {
    margin-bottom: 15px;
}

.WeekDriver .week-driver-info p span {
    font-family: "GothamPro-Medium";
}

.WeekDriver .week-driver-next-race {
    display: flex;
    flex-direction: column;
    margin: 20px auto 40px 0;
}

.WeekDriver .week-driver-next-race .next-race-heading {
    font-size: 30px;
    font-family: 'GothamPro-Bold';
    color: #000 70;
    margin-bottom: 20px;
}

.WeekDriver .week-driver-next-race .next-race-main {
    display: flex;
    align-items: center;
    font-family: "GothamPro-Medium";
    font-size: 17px;
}

.WeekDriver .week-driver-next-race .next-race-main span {
    padding: 7px 15px 4px;
    background-color: #ffe600;
    margin-left: 10px;
}

.WeekDriver .week-driver-next-race .next-race-main a {
    color: #4470ff;
    text-decoration: underline;
    margin-left: 15px;
}

@media (max-width: 650px) {
    .WeekDriver .week-driver-next-race .next-race-main {
        flex-direction: column;
        align-items: flex-start;
    }

    .WeekDriver .week-driver-next-race .next-race-main span,
    .WeekDriver .week-driver-next-race .next-race-main a {
        margin-top: 10px;
        margin-left: 0;
    }
}

.WeekDriver .week-driver-past-races {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
}

.WeekDriver .week-driver-past-races .past-races-title {
    font-size: 30px;
    font-family: 'GothamPro-Bold';
    color: #000 70;
}

.WeekDriver .week-driver-past-races .past-races-list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}