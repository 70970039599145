body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: 'GothamPro';
  src:
    url('./fonts/GothamPro/GothamPro.eot') format('eot'),
    url('./fonts/GothamPro/GothamPro.otf') format('opentype'),
    url('./fonts/GothamPro/GothamPro.woff') format('woff'),
    url('./fonts/GothamPro/GothamPro.ttf') format('truetype')
}

@font-face {
  font-family: 'GothamPro-Medium';
  src:
    url('./fonts/GothamPro-Medium/GothamPro-Medium.eot') format('eot'),
    url('./fonts/GothamPro-Medium/GothamPro-Medium.otf') format('opentype'),
    url('./fonts/GothamPro-Medium/GothamPro-Medium.woff') format('woff'),
    url('./fonts/GothamPro-Medium/GothamPro-Medium.ttf') format('truetype')
}

@font-face {
  font-family: 'GothamPro-Bold';
  src:
    url('./fonts/GothamPro-Bold/GothamPro-Bold.eot') format('eot'),
    url('./fonts/GothamPro-Bold/GothamPro-Bold.otf') format('opentype'),
    url('./fonts/GothamPro-Bold/GothamPro-Bold.woff') format('woff'),
    url('./fonts/GothamPro-Bold/GothamPro-Bold.ttf') format('truetype')
}