.event{
    width: 100%;
    height: 100%;
    margin: 70px 0;
}

.event__title {
    font-family: "GothamPro-Medium";
    display: -webkit-flex; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-left: auto;
}

.event__title__name {
    color: #111111;
    font-family: "GothamPro-Bold";
    font-size: 30px;
    background-color: #ffe600;
    padding: 8px 20px;
}

.event__title__date {
    margin-bottom: 6px;
    font-size: 15px;
    color: #636363;
}

.event__title-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 50px;
    padding: 0 5px;
}


.event-detail-back {
    text-decoration: none;
}

.event-detail-back button{
    font-family: "GothamPro-Medium";
    display: inline-block;
    align-items: center;
    color: #000;
    width: fit-content;
    transition: 100ms;
}

.event-detail-back i {
    font-size: 18px;
    margin-right: 5px;
}

.event-detail-back span {
    font-size: 16px;
}



@media only screen and (max-width:425px) {


    .event__title-row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        padding: 0 5px;
    }

    .event__title {
        flex-direction: column;
        align-items: center;
    }

    .event__title__name {
        font-size: 25px;
    }

    .event-detail-back button{
        margin-bottom: 30px;
    }

}