@keyframes pulse {
    0% {
        box-shadow: 0px 0px 1px #ffe600;
    }

    50% {
        box-shadow: 0px 0px 7px 2px #fff06e;
    }

    100% {
        box-shadow: 0px 0px 1px #ffe600;
    }

}


.About .title {
    transform: rotate(1deg);
}

.track__img {
    margin: 50px 0 0;
    width: 100%;
    min-width: 750px;
    min-height: 300px;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    position: relative;
}

.track__img_inner {
    position: relative;
}

.track__img_inner-loading {
    display: none;
}

.track__img_inner img {
    width: 100%;
    margin: 0 auto;
    user-select: none;
    -webkit-user-drag: none;
    z-index: -1;
}

.track_pin_wrap {
    position: absolute;
}

.track_pin {
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #ffe600;
    background-color: #ffe60086;
    animation: pulse 1.7s linear infinite;
    z-index: 99;
    cursor: pointer;
}

.track_pin_descr {
    position: absolute;
    bottom: 30px;
    left: 30px;
    padding: 10px;
    min-width: 220px;
    max-width: 500px;
    background-color: #ffe600;
    border-radius: 5px;
    box-shadow: 0px 5px 10px #00000040;
    font-family: "GothamPro-Medium";
    font-size: 14px;
    line-height: 1.2;
    clip-path: circle(0% at 5% 90%);
    transition: .3s ease-in-out;
}

.track_pin:hover,
.track_pin:focus {
    background-color: #ffe600;
    animation: none;
}

.track_pin:hover+.track_pin_descr,
.track_pin:focus+.track_pin_descr {
    clip-path: circle(100%)
}

.track_pin_wrap:nth-child(1) {
    top: 33%;
    left: 49%;
}

.track_pin_wrap:nth-child(2) {
    top: 68%;
    left: 50%;
}

.track_pin_wrap:nth-child(3) {
    top: 76%;
    left: 14%;
}

.track_pin_wrap:nth-child(4) {
    top: 60%;
    left: 17%;
}

.track_pin_wrap:nth-child(5) {
    top: 27%;
    left: 21%;
}

.track_pin_wrap:nth-child(6) {
    top: 31%;
    left: 9%;
}

.track_pin_wrap:nth-child(7) {
    top: 34%;
    left: 74%;
}

.track_pin_wrap:nth-child(8) {
    top: 28%;
    left: 34%;
}

.track__title {
    margin-top: 20px;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-justify-content: center;
    width: 100%;
}

.track__title p {
    max-width: 900px;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
}

.track__title ul {
    padding-left: 20px;
    margin-bottom: 10px;
}


.scroll_img {
    margin: 0px 0px 30px 0px;
    display: none;
    width: 100%;
    height: 60px;
    background-image: url(../img/scroll.jpg);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.track__descr {
    max-width: 900px;
}

.track__descr__p {
    width: 100%;
    margin-bottom: 50px;
}

.track__descr__p p {
    font-size: 20px;
    line-height: 28px;
}

.track__descr__p__title p span {
    margin-right: 10px;
    font-size: 23px;
    background-color: #ffe600;
    padding: 8px 30px;
    color: 000;
}

.track__descr__p__title {
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 4px solid #ffe600;
    display: inline-block;
}

.track__img .preloader {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.track__img .preloader-disable {
    display: none;
}


@media only screen and (max-width: 720px) {

    .track__img {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll;
        overflow-y: hidden;
        justify-content: flex-start;
        min-width: auto;
        margin: 50px 0px 10px 0px;
    }

    .track__img img {
        width: 1200px;
        height: 100%;
    }

    .scroll_img {
        display: block;
    }


}

@media only screen and (max-width: 425px) {


    .track__descr__p__title {
        display: flex;
        flex-direction: column;
        align-self: center;
    }

    .track__descr__p__title p {
        text-align: center;
    }


    .track__descr__p__title p span {
        display: block;
        margin-right: auto;
    }


}