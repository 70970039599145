.Day {
    width: 100%;
    max-width: 500px;
    min-height: 100px;
    margin-bottom: 100px;
}

.day-helper {
    color: #bdbdbd;
    font-size: 15px;
    margin-bottom: 5px;
}

.day-head {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 30px;
}

.day-main-title {
    font-size: 35px;
    color: #000;
    font-family: "GothamPro-Bold"
}

.day-main-date {
    font-family: "GothamPro-Bold";
    margin: 0 0 3px 15px;
    font-size: 23px;
    color: #bdbdbd;
}

.day-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.day-event {
    height: 110px;
    width: 250px;
    background-color: #ffe600;
    box-shadow: 0 4px 6px #00000030;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.day-event-time {
    font-size: 25px;
    font-family: "GothamPro-Bold";
    color: #000;
}

.cars-avaliable {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.cars-avaliable p:last-child{
    font-family: "GothamPro-Bold";
    font-size: 20px;
    margin-top: 5px;
}

.cars-avaliable p:last-child span{
    font-family: "GothamPro";
    font-size: 16px;
}

.day-free {
    text-align: center;
    font-size: 20px;
    color: #bdbdbd;
}




@media only screen and (max-width:425px){
    .day-head {
        flex-direction: column;
        align-items: center;
    }

    .day-main-title {
        margin-bottom: 10px;
    }
}